import { Outlet } from 'react-router-dom';
import { ReactComponent as Vert } from '../../components/vert.svg';
import './NftWallet.scss';

export interface Props {
  className?: string;
}

export default function Phase1NftWallet(props: Props) {
  const scope = 'Phase1NftWallet';
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <div className={className}>
      <div className={`${scope}-areaVert`}>
        <Vert
          aria-description="VERT | Born virtually - shaped by you"
          className={`${scope}-vert`}
        />
      </div>
      <div className={`${scope}-areaOutlet`}>
        <Outlet />
      </div>
    </div>
  );
}
