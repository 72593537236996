import React from 'react';
import './ScrollPager.scss';
import { useParams } from 'react-router-dom';

export interface Props {
  className?: string;
  numberOfPages: number;
}

export default function Phase3ScrollPager(props: Props) {
  const scope = 'Phase3ScrollPager';
  const className = [scope, props.className].filter(Boolean).join(' ');
  const pages = [...Array(props.numberOfPages)];
  const params = useParams();
  const currentPage = params.page ? parseInt(params.page) : 1;

  return (
    <div className={className}>
      <div className={`${scope}-currentPage`}>{currentPage}</div>

      <div className={`${scope}-pages`}>
        {pages.map((_, index) => {
          const classNames = [`${scope}-page`];
          if (index === currentPage - 1) {
            classNames.push(`${scope}-page--active`);
          }
          return <div className={classNames.join(' ')} key={index} />;
        })}
      </div>

      <div className={`${scope}-lastPage`}>{props.numberOfPages}</div>
    </div>
  );
}
