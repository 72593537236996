import jsonp from 'jsonp';
import { Response, createServer } from 'miragejs';

export const origin =
  process.env.REACT_APP_API_ORIGIN ??
  'https://dev.api-metamorph.artificialrome.com';

export const namespace = 'api';

export function createMirageServer({ environment = 'development' } = {}) {
  const server = createServer({
    environment,

    namespace,

    urlPrefix: origin,

    routes() {
      this.passthrough((request) => {
        const { url } = request;
        return (
          url.startsWith(`${process.env.PUBLIC_URL}/images`) ||
          !url.startsWith(origin)
        );
      });

      this.post('/registrations', (_, request) => {
        type RequestData = { email?: string };
        try {
          const { email } = JSON.parse(request.requestBody) as RequestData;
          if (!email || email.includes('invalid')) {
            return new Response(422);
          }
        } catch (error) {
          return new Response(422);
        }
        return new Response(201);
      });

      this.post('book-seat', (_, request) => {
        const data: BookSeatRequestData = JSON.parse(request.requestBody);
        const emailArray = data.email.split('@');
        if (emailArray[1].startsWith('error')) {
          return new Response(
            422,
            {},
            {
              code: emailArray[0],
              result: false,
            },
          );
        }
        return {
          code: '7314a487ff5a41fd7797d46a5dbbd7fa',
          message: 'The seat is successfully booked',
          result: true,
        };
      });

      this.get('time-slots', () => {
        return [
          {
            duration: 60,
            seats_available: 5,
            start_time: '2025-08-25 08:00:00',
          },
          {
            duration: 60,
            seats_available: 5,
            start_time: '2025-08-30 08:00:00',
          },
          {
            duration: 60,
            seats_available: 10,
            start_time: '2025-08-30 09:00:00',
          },
          {
            duration: 60,
            seats_available: 1,
            start_time: '2025-08-30 12:00:00',
          },
        ];
      });
    },
  });

  return server;
}

export async function bookSeat(data: BookSeatRequestData) {
  const response = await makeRequest<BookSeatResponseData>('book-seat', {
    body: JSON.stringify(data),
    method: 'POST',
  });
  if (!response.result) {
    throw response;
  }
  return response;
}

export function createRegistration(email: string) {
  const mailchimpParams = {
    b_beed340c8150076269ad5c0da_e1903c3071: '',
    f_id: '0010dee6f0',
    id: 'e1903c3071',
    subscribe: 'Subscribe',
    u: 'beed340c8150076269ad5c0da',
  };
  const mailchimpUrl =
    'https://artificialrome.us21.list-manage.com/subscribe/post-json';

  const url = new URL(mailchimpUrl);
  for (const [key, value] of Object.entries(mailchimpParams)) {
    url.searchParams.append(key, value);
  }
  url.searchParams.append('EMAIL', email);
  return new Promise<string>((resolve, reject) => {
    jsonp(
      url.toString(),
      {
        param: 'c',
      },
      (error, data: { msg: string; result: string }) => {
        if (error) {
          reject(error);
        } else if (data.result !== 'success') {
          reject(new Error(data.msg));
        } else {
          resolve(data.msg);
        }
      },
    );
  });
}

export async function fetchTimeSlots() {
  return makeRequest<TimeSlotData[]>('time-slots');
}

export interface BookSeatRequestData {
  email: string;
  is_japanese?: boolean;
  name: string;
  start_time: string;
  time_offset: number;
}

type BookSeatResponseData =
  | BookSeatErrorResponseData
  | BookSeatSuccessResponseData;

export interface BookSeatSuccessResponseData {
  code: string;
  result: true;
}

export interface BookSeatErrorResponseData {
  code: 'bad_request' | 'taken_seat';
  result: false;
}

export interface TimeSlotData {
  duration: number;
  seats_available: number;
  start_time: string;
}

async function makeRequest<
  T = Awaited<ReturnType<globalThis.Response['json']>>,
>(path: string, options?: RequestInit) {
  const mergedOptions = {
    cache: 'no-cache' as const,
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };
  const url = [origin, namespace, path]
    .map((segment) => {
      return segment.endsWith('/')
        ? segment.substring(0, segment.length - 1)
        : segment;
    })
    .filter(Boolean)
    .join('/');
  const response = await fetch(url, mergedOptions);
  const json: T = await response.json();
  if (response.ok) {
    return json;
  }
  throw json as unknown;
}
