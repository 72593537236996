import React, { useEffect, useRef } from 'react';
import './index.scss';
import { Trans } from 'react-i18next';
import { SectionProps } from '../../Main';
import Phase3SectionCollectionNftCollectionText from '../NftCollectionText';
import image1 from './image-1.jpg';
import image2 from './image-2.jpg';
import image3 from './image-3.jpg';

export default function Phase3SectionCollectionDesktop(props: SectionProps) {
  const scope = 'Phase3SectionCollectionDesktop';
  const sectionRef = useRef<HTMLDivElement>(null);
  const video2Ref = useRef<HTMLVideoElement>(null);
  const className = [scope, props.className].filter(Boolean).join(' ');

  useEffect(() => {
    if (props.active) {
      video2Ref.current?.play().catch(() => {
        // Do nothing
      });
    } else {
      video2Ref.current?.pause();
    }
  }, [props.active]);

  useEffect(() => {
    if (!props.sectionsContainer) {
      return;
    }
    function onContainerScroll(event: Event) {
      const element = event.target as HTMLDivElement;
      const sectionHeight = element.scrollHeight / props.sectionsCount;
      const zeroPosition = sectionHeight * (props.sectionIndex - 1);
      let progress = (element.scrollTop - zeroPosition) / sectionHeight;
      if (progress < 0) {
        progress = 0;
      } else if (progress > 1) {
        progress = 1;
      }
      sectionRef.current?.style.setProperty('--scrollProgress', `${progress}`);
    }

    props.sectionsContainer.current?.addEventListener(
      'scroll',
      onContainerScroll,
    );
    return () => {
      if (!props.sectionsContainer) {
        return;
      }
      props.sectionsContainer.current?.removeEventListener(
        'scroll',
        onContainerScroll,
      );
    };
  }, [props.sectionIndex, props.sectionsContainer, props.sectionsCount]);

  return (
    <div className={className} ref={sectionRef}>
      <div className={`${scope}-container1`}>
        <img className={`${scope}-image1`} role="presentation" src={image1} />
      </div>
      <div className={`${scope}-container2`}>
        <div className={`${scope}-subContainer2`}>
          <video
            className={`${scope}-video2`}
            loop
            muted
            playsInline
            poster={image2}
            preload="auto"
            ref={video2Ref}
          >
            <source
              src={`${process.env.PUBLIC_URL}/nft-video-2.mp4`}
              type="video/mp4"
            />
          </video>
          <div className={`${scope}-text`}>
            <Trans i18nKey="phase3-collection-text" />
          </div>
        </div>
      </div>
      <div className={`${scope}-container3`}>
        <img className={`${scope}-image3`} role="presentation" src={image3} />
      </div>
      <Phase3SectionCollectionNftCollectionText
        className={`${scope}-nftCollectionText`}
      />
    </div>
  );
}
