import React from 'react';
import './Header.scss';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../App';
import logo from '../../components/logo.svg';
import { ReactComponent as Vert } from '../../components/vert.svg';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';

export interface Props {
  className?: string;
  menuVisible?: boolean;
  onMenuButtonClick?: () => void;
}

export default function Phase3Header(props: Props) {
  const scope = 'Phase3Header';
  const systemDevice = useSystemDevice();
  const params = useParams();
  const { i18n } = useTranslation();
  const isLinkVisible = params.page !== '2';
  const homeUrl = buildRoutePath([phasePaths['3'], i18n.language, '1'], true);

  const className = [
    scope,
    props.menuVisible && `${scope}--menuVisible`,
    !isLinkVisible && `${scope}--withoutVert`,
    props.className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <header className={className}>
      <div className={`${scope}-logoContainer`}>
        <Link className={`${scope}-logoLink`} to={homeUrl}>
          <img
            alt="Diesel for successful living"
            className={`${scope}-logo`}
            src={logo}
          />
        </Link>
      </div>
      {systemDevice === SYSTEM_DEVICE.desktop ? (
        <div className={`${scope}-vertContainer`}>
          <Vert className={`${scope}-vert`} />
        </div>
      ) : (
        <button
          className={`${scope}-menuButton`}
          onClick={props.onMenuButtonClick}
          type="button"
        >
          <span />
          <span />
          <span />
        </button>
      )}
    </header>
  );
}
