import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { buildRoutePath } from '../../../App';
import { ReactComponent as Calendar } from '../../../icons/calendar.svg';
import { ReactComponent as Octagon } from './octagon.svg';

export interface Props {
  className?: string;
}

export default function Phase2RegistrationLink(props: Props) {
  const scope = 'Phase2RegistrationLink';
  const { t } = useTranslation();

  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <Link
      className={className}
      relative="path"
      to={buildRoutePath(['..', '3'])}
    >
      <div className={`${scope}-text`}>
        <div className={`${scope}-textRow1`}>{t('join-experience-date')}</div>
        <div className={`${scope}-textRow2`}>{t('join-experience')}</div>
      </div>
      <figure className={`${scope}-figure`}>
        <Octagon className={`${scope}-octagon`} role="presentation" />
        <Calendar className={`${scope}-icon`} />
      </figure>
    </Link>
  );
}
