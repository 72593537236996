import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import Button from '../../../../components/Button';
import SectionWatchesCarousel from '../Carousel';
import ColorSelectionArc from '../ColorSeletionArc';
import { WatchItem } from '../index';

interface Props {
  activeIndex: number;
  watches: WatchItem[];
  handleSlideChange: (index: number) => unknown;
  blackSilverItemIndex: number;
  isSeparatePage?: boolean;
  className?: string;
}

export default function SectionWatchesDesktop(props: Props) {
  const scope = 'Phase3SectionWatchesDesktop';

  const { t } = useTranslation();
  const { activeIndex, handleSlideChange, className: classNameArg } = props;

  const className = [scope, classNameArg].filter(Boolean).join(' ');

  function openLink() {
    window.open('https://vert.diesel.com/', '_blank');
  }

  return (
    <div
      className={`${scope}-body ${className} ${
        props.isSeparatePage ? 'full-height' : ''
      }`}
    >
      <div className={`${scope}-titleContainer`}>
        <div className={`${scope}-title`}>{t('watch-title')}</div>
        <div className={`${scope}-titleBigText`}>
          {t('watch-title-big-text')}
        </div>
      </div>
      <div>
        <ColorSelectionArc
          onClick={handleSlideChange}
          activeIndex={activeIndex}
        />
      </div>

      <div className={`${scope}-carouselContainer`}>
        <SectionWatchesCarousel
          activeIndex={activeIndex}
          handleSlideChange={handleSlideChange}
        />
      </div>
      <div className={`${scope}-descriptionContainer`}>
        <div className={`${scope}-description`}>{t('watch-description')}</div>
        {props.isSeparatePage && (
          <div className={`${scope}-restartExperienceContainer`}>
            {t('successfully-restored-text')}
            <Button
              className={`${scope}-restartExperienceButton`}
              onClick={openLink}
              text={t('restart-experience')}
              type="button"
            />
          </div>
        )}
      </div>
    </div>
  );
}
