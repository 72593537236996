import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import './index.scss';
import {
  SYSTEM_DEVICE,
  useSystemDevice,
} from '../../../../hooks/useSystemDevice';
import imageSilverLarge from '../Desktop/watch-black-silver.png';
import imageCharcoalLarge from '../Desktop/watch-charcoal.png';
import imageGoldLarge from '../Desktop/watch-gold.png';
import imageWhiteLarge from '../Desktop/watch-white.png';
import imageSilver from '../Mobile/watch-black-silver.png';
import imageCharcoal from '../Mobile/watch-charcoal.png';
import imageGold from '../Mobile/watch-gold.png';
import imageWhite from '../Mobile/watch-white.png';
import Watches from '../watches.json';

interface Props {
  activeIndex: number;
  handleSlideChange: (index: number) => unknown;
  className?: string;
}

type ImageCollection = { [key: string]: string };

const BLACK_SILVER_TEXT = 'black-silver';

export default function SectionWatchesCarousel({
  activeIndex,
  handleSlideChange,
  className: classNameArg = '',
}: Props) {
  const systemDevice = useSystemDevice();

  const WatchImages: ImageCollection =
    systemDevice === SYSTEM_DEVICE.desktop
      ? {
          white: imageWhiteLarge,
          charcoal: imageCharcoalLarge,
          gold: imageGoldLarge,
          [BLACK_SILVER_TEXT]: imageSilverLarge,
        }
      : {
          white: imageWhite,
          charcoal: imageCharcoal,
          gold: imageGold,
          [BLACK_SILVER_TEXT]: imageSilver,
        };

  const scope = 'Phase3SectionWatchesCarousel';
  const className = [scope, classNameArg].filter(Boolean).join(' ');

  return (
    <div className={className}>
      <Carousel
        selectedItem={activeIndex}
        useKeyboardArrows={true}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        showArrows={false}
        animationHandler="fade"
        swipeable={false}
        autoPlay={true}
        infiniteLoop={true}
        onChange={handleSlideChange}
      >
        {Watches.map((watch, index) => {
          return (
            <div key={index} className={`${scope}-singleWatchContainer`}>
              <img
                alt={watch.color}
                className={`${scope}-smallImage`}
                src={`${WatchImages[watch.color]}`}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
