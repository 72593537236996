import React, { SyntheticEvent, useRef, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import FullScreenImage from '../../../../components/FullScreenImage';
import { ReactComponent as ArrowLeft } from '../../../../icons/arrow-narrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-narrow-right.svg';
import image1 from './image-1.png';
import image2 from './image-2.png';
import image3 from './vert-multiplayer-01.jpg';
import image4 from './vert-multiplayer-02.jpg';
import image5 from './vert-multiplayer-03.jpg';
import image6 from './vert-multiplayer-04.jpg';
import image7 from './vert-multiplayer-05.jpg';
import image8 from './vert-multiplayer-06.jpg';
import image9 from './vert-multiplayer-07.jpg';
// eslint-disable-next-line import/order
import image10 from './vert-gameplay-04.jpg';

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
];

export interface Props {
  className?: string;
}

export default function Phase3SectionExperienceMobile(props: Props) {
  const scope = 'Phase3SectionExperienceMobile';
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const debounceTimeoutRef = useRef<number | null>(null);
  const isScrolling = useRef(false);
  const viewportRef = useRef<HTMLDivElement>(null);
  const className = [scope, props.className].filter(Boolean).join(' ');

  const [isFullScreen, setIsFullScreen] = useState(false);

  function selectPrev() {
    if (!viewportRef.current) {
      return;
    }
    viewportRef.current.scroll({
      behavior: 'smooth',
      left: viewportRef.current.scrollLeft - viewportRef.current.clientWidth,
    });
  }

  function selectNext() {
    if (!viewportRef.current) {
      return;
    }
    viewportRef.current.scroll({
      behavior: 'smooth',
      left: viewportRef.current.scrollLeft + viewportRef.current.clientWidth,
    });
  }

  function onScroll(event: SyntheticEvent<HTMLDivElement>) {
    const viewport = event.currentTarget;
    isScrolling.current = true;
    setActiveIndex(Math.round(viewport.scrollLeft / viewport.clientWidth));
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(function finishScrolling() {
      isScrolling.current = false;
    }, 100);
  }

  const openFullScreen = (imageIndex: number) => {
    setActiveIndex(imageIndex);
    setIsFullScreen(true);
  };

  return (
    <div className={className}>
      <div className={`${scope}-text`}>{t('phase3-experience-subtitle')}</div>
      <div className={`${scope}-images`} onScroll={onScroll} ref={viewportRef}>
        {images.map((image, index) => (
          <div className={`${scope}-imageContainer`} key={index}>
            <img
              className={`${scope}-image`}
              alt="Gallery Image"
              role="presentation"
              src={image}
              onClick={() => {
                openFullScreen(index);
              }}
            />
          </div>
        ))}
      </div>
      <div className={`${scope}-controls`}>
        <div className={`${scope}-controlButtons`}>
          <button
            aria-label="Previous"
            className={`${scope}-controlButtonPrev`}
            disabled={activeIndex === 0}
            onClick={selectPrev}
            type="button"
          >
            <ArrowLeft />
          </button>
          <button
            aria-label="Next"
            className={`${scope}-controlButtonNext`}
            disabled={activeIndex === images.length - 1}
            onClick={selectNext}
            type="button"
          >
            <ArrowRight />
          </button>
        </div>
        <div className={`${scope}-galleryMap`}>
          {images.map((image, index) => (
            <div
              className={`${scope}-galleryMapItem`}
              data-active={index === activeIndex}
              key={index}
            />
          ))}
        </div>
      </div>

      {isFullScreen && (
        <FullScreenImage
          activeIndex={activeIndex}
          images={images}
          onClose={() => setIsFullScreen(false)}
        />
      )}
    </div>
  );
}
