import { TFunction } from 'i18next';

export default function (t: TFunction<'translation'>) {
  return [
    {
      path: 'https://www.diesel.com/shop/category/diesel-man-marketing-metamorph',
      text: t('order-now'),
    },
    {
      path: 'https://vert.diesel.com/',
      text: t('restart-experience'),
    },
  ];
}
