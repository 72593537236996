import React from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../App';
import logo from '../../components/logo.svg';

export interface Props {
  className?: string;
}

export default function Phase1Header(props: Props) {
  const scope = 'Phase1Header';
  const className = [scope, props.className].filter(Boolean).join(' ');
  const homeUrl = buildRoutePath(phasePaths['1'], true);

  return (
    <header className={className}>
      <div className={`${scope}-logoContainer`}>
        <Link to={homeUrl} className={`${scope}-logoLink`}>
          <img
            alt="Diesel for successful living"
            className={`${scope}-logo`}
            src={logo}
          />
        </Link>
      </div>
      <div className={`${scope}-timeline`}>
        <div className={`${scope}-timelineStart`}>02 Nov</div>
        <div className={`${scope}-timelineSeparator`} />
        <div className={`${scope}-timelineEnd`}>2023</div>
      </div>
    </header>
  );
}
