import React, { InputHTMLAttributes } from 'react';
import './Checkbox.scss';

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  children?: React.ReactNode;
  className?: string;
  label?: string;
}

export default function Checkbox(props: Props) {
  const scope = 'Checkbox';

  const { children, className: propsClassName, label, ...inputProps } = props;
  const className = [scope, propsClassName].filter(Boolean).join(' ');

  return (
    <label className={className}>
      <input className={`${scope}-input`} type="checkbox" {...inputProps} />
      {(children || label) && (
        <div className={`${scope}-label`}>{children || label}</div>
      )}
    </label>
  );
}
