import React from 'react';
import './LegalLinks.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Phase, buildRoutePath, phasePaths } from '../App';

export interface Props {
  className?: string;
  onLinkClick?: () => void;
  phase: Phase;
}

export default function LegalLinks(props: Props) {
  const scope = 'LegalLinks';
  const className = [scope, props.className].filter(Boolean).join(' ');
  const { i18n, t } = useTranslation();

  return (
    <div className={className}>
      <Link
        onClick={props.onLinkClick}
        to={buildRoutePath(
          [phasePaths[props.phase], i18n.language, 'privacy'],
          true,
        )}
      >
        {t('privacy')}
      </Link>
      <Link
        onClick={props.onLinkClick}
        to={buildRoutePath(
          [phasePaths[props.phase], i18n.language, 'terms'],
          true,
        )}
      >
        {t('terms')}
      </Link>
      <Link
        onClick={props.onLinkClick}
        to={buildRoutePath(
          [phasePaths[props.phase], i18n.language, 'imprint'],
          true,
        )}
      >
        {t('imprint')}
      </Link>
    </div>
  );
}
