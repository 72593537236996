import React, { ButtonHTMLAttributes } from 'react';
import './Button.scss';
import { ReactComponent as Arrow } from '../icons/arrow-right.svg';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  className?: string;
  text?: string;
}

export default function Button(props: Props) {
  const scope = 'Button';
  const {
    Icon,
    children,
    className: propsClassName,
    text,
    ...buttonProps
  } = props;
  const content = children ?? text;
  const className = [scope, content && `${scope}--with-content`, propsClassName]
    .filter(Boolean)
    .join(' ');

  const IconComponent = Icon !== undefined ? Icon : Arrow;

  return (
    <button className={className} {...buttonProps}>
      {content && <span className={`${scope}-text`}>{content}</span>}
      {IconComponent && (
        <IconComponent className={`${scope}-icon`} role="presentation" />
      )}
    </button>
  );
}
