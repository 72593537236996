import React, { Fragment } from 'react';
import './MobileMenu.scss';
import { Link, To } from 'react-router-dom';
import { Phase } from '../App';
import DiscoverLink from './DiscoverLink';
import LegalLinks from './LegalLinks';
import MadeBySoil from './MadeBySoil';
import SocialLinks from './SocialLinks';

export interface Props {
  className?: string;
  links: LinkProps[];
  onLinkClick?: () => void;
  phase: Phase;
  visible?: boolean;
  shouldOpenBlankTab?: boolean;
}

interface LinkProps {
  path: To;
  text: string;
}

export default function MobileMenu(props: Props) {
  const scope = 'MobileMenu';
  const className = [
    scope,
    props.visible && `${scope}--visible`,
    props.className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div aria-hidden={!props.visible} className={className}>
      <div className={`${scope}-body`}>
        {props.links.map((linkData, index) => (
          <Fragment key={index}>
            <div className={`${scope}-spacer`} />
            <Link
              className={`${scope}-navLink`}
              onClick={props.onLinkClick}
              relative="path"
              to={linkData.path}
              target={props.shouldOpenBlankTab ? '_blank' : '_self'}
            >
              {linkData.text}
            </Link>
          </Fragment>
        ))}
      </div>
      <div className={`${scope}-footer`}>
        <div className={`${scope}-footerRow1`}>
          <DiscoverLink />
          <div className={`${scope}-languageSwitcher`} />
        </div>
        <div className={`${scope}-footerRow2`}>
          <SocialLinks />
          <LegalLinks onLinkClick={props.onLinkClick} phase={props.phase} />
        </div>
        <div className={`${scope}-footerRow3`}>
          <MadeBySoil />
        </div>
      </div>
    </div>
  );
}
