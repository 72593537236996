import React, { ChangeEvent } from 'react';
import './LanguageSwitcher.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Phase, buildRoutePath } from '../App';
import { locales } from '../i18n';

export interface Props {
  className?: string;
  phase: Phase;
}

export default function LanguageSwitcher(props: Props) {
  const scope = 'LanguageSwitcher';
  const className = [scope, props.className].filter(Boolean).join(' ');
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  let path = location.pathname;
  if (path.startsWith('/')) {
    path = path.slice(1);
  }
  const pathSegments = path.split('/').slice(-2);

  function changeLanguage(event: ChangeEvent<HTMLSelectElement>) {
    const newPathSegments = pathSegments.slice();
    newPathSegments[0] = event.target.value;
    const redirectPath = buildRoutePath(
      ['..', props.phase, ...newPathSegments],
      true,
    );
    navigate(redirectPath);
  }

  return (
    <label className={className}>
      <span className={`${scope}-labelInvisible`}>Language</span>
      <select
        className={`${scope}-select`}
        onChange={changeLanguage}
        value={i18n.language}
      >
        {locales.map((locale) => (
          <option key={locale} value={locale}>
            {locale}
          </option>
        ))}
      </select>
      <span className={`${scope}-selectFocus`} />
    </label>
  );
}
