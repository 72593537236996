import { createContext, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Outlet } from 'react-router-dom';
import CookiesConsentBanner from '../components/CookiesConsentBanner';
import TrackingCodes from '../components/TrackingCodes';

export default function Root() {
  const [isConsentVisible, setIsConsentVisible] = useState(false);
  const context: GlobalContext = { isConsentVisible, setIsConsentVisible };
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <GlobalContext.Provider value={context}>
        <Outlet />
        <CookiesConsentBanner />
        <TrackingCodes />
      </GlobalContext.Provider>
    </CookiesProvider>
  );
}

export const GlobalContext = createContext<GlobalContext>({
  isConsentVisible: false,
  setIsConsentVisible: () => {
    // Do nothing
  },
});

export interface GlobalContext {
  isConsentVisible: boolean;
  setIsConsentVisible: (value: boolean) => void;
}
