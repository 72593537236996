import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import soilLogo from './soil-logo.svg';

export interface Props {
  className?: string;
}

export default function MadeBySoil(props: Props) {
  const scope = 'MadeBySoil';
  const className = [scope, props.className].filter(Boolean).join(' ');
  const { t } = useTranslation();

  return (
    <div className={className}>
      {t('by-ar-made-with')}{' '}
      <a
        href="https://artificialrome.com/soil"
        rel="noreferrer"
        target="_blank"
      >
        <img alt="SOIL" className={`${scope}-soilLogo`} src={soilLogo} />
      </a>
    </div>
  );
}
