export interface Props {
  className?: string;
}

export default function TermsAndConditions(props: Props) {
  const scope = 'TermsAndConditions';
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <div className={className}>
      <h1>Vert Metamorph Experience - Terms & Conditions</h1>
      <p>
        These Terms and Conditions (the “Terms”) set forth the terms and
        conditions on the access and use of the Vert Metamorph Experience
        provided by Artificial Rome GmbH (the “Company”, “company” or “we”). You
        (“User” or “you”) must agree to these Terms by reading the entire Terms
        before using this platform.
      </p>

      <h2>Purpose</h2>
      <p>
        The purpose of the Terms is to set forth the terms and conditions for
        the use of the Vert Metamorph Experience and the rights and obligations
        between the Company and the User.
      </p>

      <h2>Application and agreement to the terms</h2>
      <ol>
        <li>
          All Users shall agree to use the Vert Metamorph Experience only for
          purposes that are legal and proper, and in accordance with these
          Terms.
        </li>

        <li>
          The Vert Metamorph Experience is limited to users over 18 (eighteen)
          years old. Furthermore, if users will be using the Vert Metamorph
          Experience on behalf of, or for the purposes of, a business
          enterprise, then such business enterprise must also agree to these
          Terms prior to using the Vert Metamorph Experience.
        </li>

        <li>
          If there are separate terms applicable to the Vert Metamorph
          Experience, Users shall also comply with such terms as well as these
          Terms in using the Vert Metamorph Experience.
        </li>
      </ol>

      <h2>Access</h2>
      <p>
        The Vert Metamorph Experience has a limited run-time of around 15
        minutes, after which it will automatically end with a cinematic closing.
        <br />
        You can also visit{' '}
        <a href="http://www.vert.diesel.com" rel="noreferrer" target="_blank">
          www.vert.diesel.com
        </a>{' '}
        to enter the Vert Metamorph Experience without pre-booking.
      </p>
      <p>
        If there is space in the Vert Metamorph Experience you will be admitted.
        If not, you may have to queue for entry.
        <br />
        All registrations and visitors acknowledge and agree that we, in our
        sole discretion, reserves the right to change any and all aspects of the
        Vert Metamorph Experience, including but not limited to, the Vert
        Metamorph Experience name, theme, look, brands involved and interface.
      </p>
      <p>
        We may also exclude any prospective participant from registering for or
        participating in the event. This is the company’s sole discretion. Once
        you have completed your registration, you will receive your registration
        confirmation by email. Please ensure that your valid email is entered
        correctly on the registration form. Be sure to check your junk email box
        too in case any of your email(s) are caught by spam filters.
      </p>

      <h2>The event</h2>
      <p>
        The Vert Metamorph Experience will run from November 2nd (1600 CET) to
        November 17th (900 CET) on{' '}
        <a href="http://www.vert.diesel.com" rel="noreferrer" target="_blank">
          www.vert.diesel.com
        </a>
      </p>
      <p>
        All registrations and visitors acknowledge and agree that the company
        reserves the right to remove you from the event if the company, in its
        sole discretion, determines that your participation or behaviour creates
        a disruption or hinders the event or the enjoyment of the event for
        other attendees.
      </p>

      <h2>Your data</h2>
      <p>
        We ask for your name and email address to register for the event so We
        can keep you updated about our event, confirm your booking and send you
        a link to access the Vert Metamorph Experience. We will also use it to
        send out update content and information during the event
      </p>
      <p>
        We will store your data for as long as the relevant purpose requires
        marketing updates related to the Metamorph experience evolution.
      </p>
      <p>
        If any of the information that we have about you is incorrect, or you
        wish to have information (including Personal Data) removed from our
        records, you may do so by contacting us at e-mail address{' '}
        <a href="mailto:diesel-vert@artificialrome.com">
          diesel-vert@artificialrome.com
        </a>
      </p>
      <p>We will not sell your data or share it with third parties.</p>
      <p>
        The Personal Data that you provide to us is generally stored on servers
        located in the United States.Although we use security measures to help
        protect your Personal Data against loss, misuse or unauthorised
        disclosure, we cannot guarantee the security of information transmitted
        to us over the internet. All information you provide to us is stored on
        secure servers.
      </p>
      <p>
        By posting and sharing content while using the chat function during the
        Vert Metamorph Experience event, you assume full responsibility for any
        risks that may arise. While we have implemented reasonable security
        measures to safeguard your personal information, we cannot guarantee the
        actions of other users who may access the content you posted as part of
        the Event. Furthermore, we are not accountable for any attempts to
        bypass privacy settings or security measures that you or we have
        established.
      </p>

      <h2>Ownership of the vert metamorph experience</h2>
      <ol>
        <li>
          All legal rights, titles and interests into the Vert Metamorph
          Experience (including without limitation the Vert Metamorph Experience
          space and avatars) and all materials and content contained therein,
          including any and all graphics, images, text, files, designs, systems,
          methods, information, computer code, software, scripts and all
          intellectual property rights (whether registered or unregistered in
          any jurisdiction) related to any of the foregoing (collectively, the
          “Vert Metamorph Experience Content”) are the sole property of the
          third-party right owners that authorized the use of Content to the
          Company. All rights into the Vert Metamorph Experience and Vert
          Metamorph Experience Content not expressly granted to you under these
          Terms are expressly reserved.
        </li>
        <li>
          Users may take screenshots of the Vert Metamorph Experience in the
          manner prescribed by the Company, These screenshots are only allowed
          for the personal use of the User. The Company strictly prohibits the
          User to use such screenshots in ways that violate public order or
          morality, violate or are likely to violate laws or regulations, or
          damage or is likely to damage the fame and reputation of the Company,
          or in ways that may mislead the public about the Company’s brand
          and/or Intellectual Property Rights.
        </li>
        <li>
          Notwithstanding the preceding paragraphs, Users are not allowed to
          record videos of the Vert Metamorph Experience (nor its virtual
          image), without the prior written consent of the Company. Users
          acknowledge that a breach of this paragraph is a violation of this
          Term and an infringement of the Company’s rights, and understand it
          may be subject to a claim for damages or other legal actions by the
          Company.
        </li>
      </ol>

      <h2>User communication and content</h2>
      <ol>
        <li>
          The Vert Metamorph Experience may include functions that allow Users
          to submit, display, transmit or otherwise make available certain
          content (“User Content”), which allow Users to communicate with other
          Users (“User Communication Function”). By submitting, displaying,
          transmitting or otherwise making available User Content, you agree to
          grant the Company an exclusive, transferable, royalty-free right to
          use (including the right to sublicense to third parties) of all User
          Content indefinitely, in Germany and throughout the world, for our
          lawful business, including operating the Vert Metamorph Experience,
          improving the Vert Metamorph Experience,. The User agrees not to
          exercise his/her/their moral rights against the Company or any third
          parties to whom the Company has sublicensed such rights.
        </li>
        <li>
          You represent and warrant the accuracy, suitability, completeness,
          veracity, legality and quality of all User Content posted or
          contributed by you. In addition, you shall ensure that the User
          Content does not contain confidential information of third parties and
          does not infringe on the rights and interests of third parties. Users
          shall be fully responsible for all User Content, and in the case that
          any damage is caused to the Company deriving from such User Content,
          You agree to be liable for such damage and shall compensate all damage
          caused to the Company.
        </li>
        <li>
          Users acknowledge and understand that there is a possibility that
          information under the User Communication Function may be disclosed to
          those who are not subject to disclosure due to operational or other
          unavoidable circumstances.
        </li>
        <li>
          Users agree to use the User Communication Function in compliance with
          these Terms. Users may not submit, display, transmit or otherwise make
          available certain content that is described in “Restricted Matters”,
          including without limitation, excessively violent or explicitly sexual
          expressions, expressions that amount to child pornography or child
          abuse, expressions that lead to discrimination by race, national
          origin, creed, gender, social status, family origin expressions that
          induce or encourage suicide, self-injurious behaviour or drug abuse,
          expressions that lead to the misrepresentation of the Company and/or a
          third party, expressions that intentionally spreading false
          information, nor conduct acts of sending the same or similar messages
          continuously, or other acts that the Company deems as spamming.
        </li>
        <li>
          You acknowledge and understand that the Company will not be monitoring
          the User Communication Function and you will use User Communication
          Functions at your own risk. The User Communication Function will
          allow, if present, you to block or unblock other Users, at any time of
          your choice, and in the case where Users find any communications with
          other Users to be offensive or discomfortable, you should cease
          communications with these Users by blocking such Users. Blocked Users
          will not be able to communicate with you until you unblock the blocked
          User. Notwithstanding the foregoing, you hereby expressly waive and
          release the Company, its affiliates, officers, employees, directors,
          shareholders, parents, subsidiaries, agents, suppliers, and licensors
          from all liability arising from the use of the User Communication
          Function, and/or any conflicts and disputes between other Users.
        </li>
      </ol>

      <h2>Restricted matters</h2>
      <ol>
        <li>
          Users may not, and may not allow any third party to, engage in any of
          the following acts in connection with your use of the Vert Metamorph
          Experience:
          <ol>
            <li>
              Acts that violate the laws and regulations, court verdicts,
              resolutions or orders, or legally binding administrative measures;
            </li>
            <li>
              Acts that may be in violation of public order, morals or customs;
            </li>
            <li>
              Acts that infringe intellectual property rights (including without
              limitation, copyrights, trademarks and patent rights, rights to
              fame, privacy and all other rights granted by law or by contract)
              of the Company and/or a third party;
            </li>
            <li>
              Acts of posting or transmitting: excessively violent or explicitly
              sexual expressions; expressions that amount to child pornography
              or child abuse; expressions that lead to discrimination by race,
              national origin, creed, gender, social status, family origin,
              etc.; expressions that induce or encourage suicide, self-injurious
              behavior or drug abuse; or expressions that include anti-social
              content and lead to the discomfort of others;
            </li>
            <li>
              Acts that lead to the misrepresentation of the Company and/or a
              third party or that intentionally spreading false information;
            </li>
            <li>
              Acts of sending the same or similar messages continuously or to a
              large, indefinite number of Users, or other acts that the Company
              deems as spamming;
            </li>
            <li>
              Acts of using the Vert Metamorph Experience for sales, marketing,
              advertising, solicitation or other commercial purposes (except for
              those approved by the Company); using the Vert Metamorph
              Experience for the purpose of sexual conduct or obscene acts;
              using the Vert Metamorph Experience for the purpose of meeting or
              engaging in sexual encounters with an unknown third party; using
              the Vert Metamorph Experience for the purpose of harassment or
              libellous attacks against other Users; or otherwise using the Vert
              Metamorph Experience for purposes other than as intended by the
              Vert Metamorph Experience;
            </li>
            <li>
              Acts that benefit or involve collaboration with Antisocial Forces;
            </li>
            <li>
              Acts that are related to religious activities or invitations to
              certain religious groups;
            </li>
            <li>
              Acts of unauthorised or improper collection, disclosure or
              provision of any other person’s personal information, registered
              information, user history, or the like;
            </li>
            <li>
              Acts of interfering with the servers and/or network systems of the
              Vert Metamorph Experience; fraudulently manipulating the Vert
              Metamorph Experience by means of bots or other technical measures;
              deliberately using defects of the Vert Metamorph Experience,
              making unreasonable inquires and/or under claims such as
              repeatedly asking the same questions beyond what is necessary, and
              other acts of interfering with or hindering the Company’s
              operation of the Vert Metamorph Experience or other Users’ use of
              the Vert Metamorph Experience;
            </li>
            <li>
              Acts of decoding the software of the Vert Metamorph Experience,
              such as by way of reverse engineering, disassembling or the like,
              for unreasonable purposes or in an unfair manner;
            </li>
            <li>
              Acts of altering the Vert Metamorph Experience, or falsifying
              available information in relation with the Vert Metamorph
              Experience;
            </li>
            <li>
              Acts that aid or encourage any acts stated in clauses (1) to (13);
              and
            </li>
            <li>
              Acts other than those set forth in clauses (1) to (14) that the
              Company reasonably deems to be inappropriate.
            </li>
          </ol>
        </li>
        <li>
          In the case where the User violates these Terms (Including without
          limitation, cases where any of the items in the preceding paragraph
          has been violated), the Company may, at its discretion, take action as
          it deems appropriate. The Company expressly reserves the right to take
          any and all appropriate legal action against anyone who, in its sole
          discretion, violates, or is suspected of violating, the Terms. You
          acknowledge, consent and agree that the Company may access, preserve
          and disclose any information relating to you or your use of the Vert
          Metamorph Experience, if required to do so by law or if the Company
          considers that such access, preservation or disclosure is reasonably
          necessary.
        </li>
      </ol>

      <h2>Third party websites</h2>
      <p>
        Our website and event may contain links to third-party websites or
        services. These third-party sites have separate and independent privacy
        policies. We have no responsibility or liability for the content and
        activities of these linked sites. Nonetheless, we seek to protect the
        integrity of our site and welcome any feedback about these sites.
      </p>

      <h2>Changes & access period</h2>
      <p>
        The Company reserves the right to change all or part of the Vert
        Metamorph Experience, or to discontinue the provision of this Vert
        Metamorph Experience, without prior notice to Users. The Company shall
        not be liable for any damage caused to Users as a result of such changes
        or discontinuation.
      </p>
      <p>
        The Vert Metamorph Experience will be accessible to you only for the
        period specified by the Company. The Vert Metamorph Experience may be
        re-opened and accessible for the public or to restricted users with the
        same content or after changes to materials and content, which shall be
        determined by the Company at its own discretion.
        <br />
        Notwithstanding the preceding paragraph, the Company may suspend or
        discontinue the Vert Metamorph Experience without prior notice to the
        User if we determine that any of the following reasons are present:
      </p>
      <ol data-level2>
        <li>
          When performing maintenance, inspection or updating of computer
          systems for the implementation of the Vert Metamorph Experience;
        </li>
        <li>
          If the Vert Metamorph Experience becomes difficult due to force
          majeure such as earthquake, lightning, fire, power failure or natural
          disaster;
        </li>
        <li>
          When a computer or communication line is shut down due to an accident;
        </li>
        <li>
          In the event that the Company determines that it is otherwise
          difficult to implement this Vert Metamorph Experience.
        </li>
      </ol>

      <p>
        The Company shall not be liable for any disadvantages or damages
        incurred by the Users due to the suspension or discontinuation of this
        Vert Metamorph Experience in accordance with the preceding paragraph.
      </p>

      <h2>Intellectual property</h2>
      <p>
        All intellectual property rights in and to the Vert Metamorph
        Experience, its content, and all materials distributed at or in
        connection with the event are owned by the company and/or by third
        parties’ rights owners that have duly authorized the Company for the
        uses related to the Event.
      </p>
      <p>
        You may not use or reproduce or allow anyone to use or reproduce any
        trademarks or other trade names appearing at the event, or in any
        materials distributed at or in connection with the event for any reason
        without the prior written permission of the company.
      </p>
      <h2>Legal disputes</h2>
      <p>
        Any legal disputes will be settled in accordance with German law and the
        courts of Berlin.
      </p>
    </div>
  );
}
