import React, { SyntheticEvent, useRef, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeft } from '../../../../icons/arrow-narrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-narrow-right.svg';
import Phase2SectionCollectionNftCollectionText from '../NftCollectionText';
import image1 from './image-1.jpg';
import image2 from './image-2.jpg';
import image3 from './image-3.jpg';

export interface Props {
  className?: string;
}

const images = [image1, image2, image3];

export default function Phase2SectionCollectionMobile(props: Props) {
  const scope = 'Phase2SectionCollectionMobile';
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const debounceTimeoutRef = useRef<number | null>(null);
  const isScrolling = useRef(false);
  const viewportRef = useRef<HTMLDivElement>(null);
  const className = [scope, props.className].filter(Boolean).join(' ');

  function selectPrev() {
    if (!viewportRef.current) {
      return;
    }
    viewportRef.current.scroll({
      behavior: 'smooth',
      left: viewportRef.current.scrollLeft - viewportRef.current.clientWidth,
    });
  }

  function selectNext() {
    if (!viewportRef.current) {
      return;
    }
    viewportRef.current.scroll({
      behavior: 'smooth',
      left: viewportRef.current.scrollLeft + viewportRef.current.clientWidth,
    });
  }

  function onScroll(event: SyntheticEvent<HTMLDivElement>) {
    const viewport = event.currentTarget;
    isScrolling.current = true;
    setActiveIndex(Math.round(viewport.scrollLeft / viewport.clientWidth));
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(function finishScrolling() {
      isScrolling.current = false;
    }, 100);
  }

  return (
    <div className={className}>
      <Phase2SectionCollectionNftCollectionText
        className={`${scope}-nftCollectionText`}
      />
      <div className={`${scope}-gallery`}>
        <div
          className={`${scope}-images`}
          onScroll={onScroll}
          ref={viewportRef}
        >
          {images.map((image, index) => (
            <div className={`${scope}-imageContainer`} key={index}>
              <img
                className={`${scope}-image`}
                role="presentation"
                src={image}
              />
            </div>
          ))}
        </div>
        <div className={`${scope}-controls`}>
          <div className={`${scope}-controlButtons`}>
            <button
              aria-label="Previous"
              className={`${scope}-controlButtonPrev`}
              disabled={activeIndex === 0}
              onClick={selectPrev}
              type="button"
            >
              <ArrowLeft />
            </button>
            <button
              aria-label="Next"
              className={`${scope}-controlButtonNext`}
              disabled={activeIndex === images.length - 1}
              onClick={selectNext}
              type="button"
            >
              <ArrowRight />
            </button>
          </div>
          <div className={`${scope}-galleryMap`}>
            {images.map((image, index) => (
              <div
                className={`${scope}-galleryMapItem`}
                data-active={index === activeIndex}
                key={index}
              />
            ))}
          </div>
        </div>
        <div className={`${scope}-galleryText`}>
          {t('phase2-collection-text')}
        </div>
      </div>
    </div>
  );
}
