import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import Polygon from '../../../../components/Polygon';
import SectionWatchesCarousel from '../Carousel';
import { WatchItem } from '../index';

interface Props {
  activeIndex: number;
  watches: WatchItem[];
  handleSlideChange: (index: number) => unknown;
  blackSilverItemIndex: number;
  isSeparatePage?: boolean;
  className?: string;
}

export default function SectionWatchesMobile({
  activeIndex,
  handleSlideChange,
  watches,
  blackSilverItemIndex,
  isSeparatePage = false,
  className: classNameArg = '',
}: Props) {
  const scope = 'Phase3SectionWatchesMobile';
  const className = [scope, classNameArg].filter(Boolean).join(' ');

  const { t } = useTranslation();

  return (
    <div
      className={`${scope}-body ${className} ${
        isSeparatePage ? 'full-height' : ''
      }`}
    >
      <div className={`${scope}-carouselContainer`}>
        <SectionWatchesCarousel
          activeIndex={activeIndex}
          handleSlideChange={handleSlideChange}
        />
      </div>
      <div className={`${scope}-bottomContainer`}>
        <div className={`${scope}-textContainer`}>
          <div className={`${scope}-title`}>{t('watch-title-mobile')}</div>
          <div className={`${scope}-description`}>{t('watch-description')}</div>
        </div>
        <div className={`${scope}-colorPickerContainer`}>
          {watches.map((watch, index) => {
            return (
              <Polygon
                key={index}
                onClick={() => handleSlideChange(index)}
                isActive={activeIndex === index}
                shouldShowBlackSilver={index === blackSilverItemIndex}
                color={watch.color}
                className={`${scope}-colorPickerItem`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
