import { ReactNode, useState } from 'react';
import './ContentPage.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Link, LoaderFunctionArgs } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../App';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import LegalLinks from '../../components/LegalLinks';
import MadeBySoil from '../../components/MadeBySoil';
import MobileMenu from '../../components/MobileMenu';
import SocialLinks from '../../components/SocialLinks';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';
import { isValidLocale } from '../../i18n';
import Phase2Header from './Header';
import mobileMenuLinks from './mobileMenuLinks';
import mobileMenuLinksForIndividualWatches from './mobileMenuLinksForIndividualWatchesPage';

export interface Props {
  className?: string;
  children?: ReactNode;
  'data-name'?: string;
  showFooter?: boolean;
}

export default function Phase2ContentPage(props: Props) {
  const scope = 'Phase2ContentPage';
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const systemDevice = useSystemDevice();
  const { i18n, t } = useTranslation();
  const className = [
    scope,
    isMenuVisible && `${scope}--menuVisible`,
    props.className,
  ]
    .filter(Boolean)
    .join(' ');

  const showFooter = props.showFooter ?? true;

  const { hash } = location;
  const isIndividualWatchesPage = hash.endsWith('/watch');

  function toggleMenuVisibility() {
    setIsMenuVisible((value) => !value);
  }

  return (
    <div className={className} data-name={props['data-name']}>
      <Phase2Header
        className={`${scope}-header`}
        menuVisible={isMenuVisible}
        onMenuButtonClick={toggleMenuVisibility}
      />
      <div className={`${scope}-content`}>{props.children}</div>
      {showFooter && (
        <div className={`${scope}-footer`}>
          <div className={`${scope}-footerLinksAndLanguage`}>
            <SocialLinks className={`${scope}-socialLinks`} />
            <div className={`${scope}-footerLegalAndLanguage`}>
              <LegalLinks phase="2" />
              <LanguageSwitcher
                className={`${scope}-languageSwitcher`}
                phase="2"
              />
            </div>
          </div>
          <div className={`${scope}-footerDisclaimer`}>
            <Trans i18nKey="phase2-disclaimer-text">
              <Link
                to={buildRoutePath(
                  [phasePaths['2'], i18n.language, 'terms'],
                  true,
                )}
              />
            </Trans>
          </div>
          <MadeBySoil className={`${scope}-footerMadeBy`} />
        </div>
      )}

      {systemDevice === SYSTEM_DEVICE.mobile ? (
        isIndividualWatchesPage ? (
          <MobileMenu
            className={`${scope}-mobileMenu`}
            links={mobileMenuLinksForIndividualWatches(t)}
            shouldOpenBlankTab={true}
            onLinkClick={toggleMenuVisibility}
            phase="2"
            visible={isMenuVisible}
          />
        ) : (
          <MobileMenu
            className={`${scope}-mobileMenu`}
            links={mobileMenuLinks(t)}
            onLinkClick={toggleMenuVisibility}
            phase="2"
            visible={isMenuVisible}
          />
        )
      ) : null}
    </div>
  );
}

export function loader({ params }: LoaderFunctionArgs): RouteParams {
  const locale = params.locale ?? '';

  if (!isValidLocale(locale)) {
    throw new Response('', {
      status: 404,
      statusText: 'Not Found',
    });
  }

  return { locale };
}

export interface RouteParams {
  locale: string;
}
