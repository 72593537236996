import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { SectionProps } from '../Main';

export default function Phase3SectionTrailer(props: SectionProps) {
  const scope = 'Phase3SectionTrailer';
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const className = [
    scope,
    isVideoOpen && `${scope}--videoOpen`,
    props.className,
  ]
    .filter(Boolean)
    .join(' ');

  function toggleIsVideoOpen() {
    setIsVideoOpen((value) => !value);
  }

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) {
      return;
    }
    if (isVideoOpen) {
      void videoElement.play();
    } else {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  }, [isVideoOpen]);

  return (
    <section className={className}>
      <div className={`${scope}-body`}>
        <div className={`${scope}-title`}>{t('land-of-tempus')}</div>
        <Button
          className={`${scope}-buttonWatch`}
          onClick={toggleIsVideoOpen}
          text={t('watch-now')}
          type="button"
        />
      </div>
      <div className={`${scope}-videoOverlay`} hidden={!isVideoOpen}>
        <video
          className={`${scope}-video`}
          controls
          controlsList="nodownload"
          onEnded={toggleIsVideoOpen}
          preload="auto"
          ref={videoRef}
        >
          <source
            src={`${process.env.PUBLIC_URL}/trailer.mp4`}
            type="video/mp4"
          />
        </video>
        <button
          className={`${scope}-buttonCloseVideo`}
          onClick={toggleIsVideoOpen}
          type="button"
        >
          {t('close-video')}
        </button>
      </div>
    </section>
  );
}
