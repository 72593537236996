import React from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../App';
import SocialLinks from '../../components/SocialLinks';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';

export interface Props {
  className?: string;
}

export default function Phase1Footer(props: Props) {
  const scope = 'Phase1Footer';
  const { t } = useTranslation();
  const systemDevice = useSystemDevice();
  const className = [scope, props.className].filter(Boolean).join(' ');

  const availabilityMessage = (
    <div className={`${scope}-availabilityMessage`}>
      {t('availability-message')}
    </div>
  );

  const infoLinks = (
    <div className={`${scope}-infoLinks`}>
      <Link
        className={`${scope}-infoLink`}
        to={buildRoutePath([phasePaths['1'], 'legal'], true)}
      >
        {t('terms-&-privacy')}
      </Link>
      <a
        className={`${scope}-infoLink`}
        href="#"
        rel="noreferrer"
        target="_blank"
      >
        {t('imprint')}
      </a>
    </div>
  );

  return (
    <footer className={className}>
      {systemDevice === SYSTEM_DEVICE.mobile ? (
        <>
          {availabilityMessage}
          <div className={`${scope}-links`}>
            <SocialLinks className={`${scope}-socialLinks`} />
            {infoLinks}
          </div>
        </>
      ) : (
        <>
          <SocialLinks className={`${scope}-socialLinks`} />
          {availabilityMessage}
          {infoLinks}
        </>
      )}
    </footer>
  );
}
