import React from 'react';
import './index.scss';
import { SYSTEM_DEVICE, useSystemDevice } from '../../../hooks/useSystemDevice';
import { SectionProps } from '../Main';
import Phase3SectionCollectionDesktop from './Desktop';
import Phase3SectionCollectionMobile from './Mobile';

export default function Phase3SectionCollection(props: SectionProps) {
  const scope = 'Phase3SectionCollection';
  const systemDevice = useSystemDevice();
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <section className={className}>
      {systemDevice === SYSTEM_DEVICE.desktop ? (
        <Phase3SectionCollectionDesktop
          active={props.active}
          className={`${scope}-body`}
          sectionIndex={props.sectionIndex}
          sectionsContainer={props.sectionsContainer}
          sectionsCount={props.sectionsCount}
        />
      ) : (
        <Phase3SectionCollectionMobile className={`${scope}-body`} />
      )}
    </section>
  );
}
