import dayjs from 'dayjs';
import React, { ChangeEvent, forwardRef, useState } from 'react';
import './DateTimeSelect.scss';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  onChange?: (
    event: ChangeEvent<HTMLSelectElement>,
    value: Date | null,
  ) => void;
  options?: Date[];
  required?: boolean;
  value?: Date;
}

const DateTimeSelect = forwardRef<HTMLSelectElement, Props>(
  function DateTimeSelect(props, ref) {
    const scope = 'DateTimeSelect';
    const [selectedDateString, setSelectedDateString] = useState(
      props.value?.toLocaleDateString() ?? '',
    );

    const { children, className: propsClassName, label } = props;

    const className = [scope, propsClassName].filter(Boolean).join(' ');

    const dateTimeOptionsNormalized = (props.options ?? []).reduce(
      (result, date) => {
        const dateString = date.toLocaleDateString();
        const timeString = dayjs(date).format('HH:mm');
        let dateSlot = result.find((dateSlot) => dateSlot.label === dateString);
        if (!dateSlot) {
          dateSlot = {
            label: dateString,
            timeSlots: [],
          } as DateSlotNormalized;
          result.push(dateSlot);
        }
        dateSlot.timeSlots.push({
          label: timeString,
          value: date,
        });

        return result;
      },
      [] as DateSlotNormalized[],
    );

    const timeOptions =
      dateTimeOptionsNormalized.find(
        (dateSlot) => dateSlot.label === selectedDateString,
      )?.timeSlots ?? [];

    const isDateSelected = dateTimeOptionsNormalized
      .map(({ label }) => label)
      .includes(selectedDateString);
    const isTimeSelected =
      props.value &&
      timeOptions
        .map(({ label }) => label)
        .includes(props.value.toLocaleTimeString());

    function onDateChange(event: ChangeEvent<HTMLSelectElement>) {
      setSelectedDateString(event.target.value);
      props.onChange?.(event, null);
    }

    function onTimeChange(event: ChangeEvent<HTMLSelectElement>) {
      const selectedTimeString = event.target.value;
      const timeOption = timeOptions.find(
        ({ label }) => label === selectedTimeString,
      );
      const value = timeOption?.value ?? null;
      props.onChange?.(event, value);
    }

    return (
      <div className={className}>
        {(children || label) && (
          <div className={`${scope}-label`}>{children || label}</div>
        )}
        <div className={`${scope}-inputs`}>
          <label className={`${scope}-selectWrapper`}>
            <span className={`${scope}-labelInvisible`}>Date</span>
            <select
              className={`${scope}-select`}
              onChange={onDateChange}
              required={props.required}
              value={selectedDateString}
            >
              {!isDateSelected && <option disabled hidden value="" />}
              {dateTimeOptionsNormalized.map((dateSlot) => (
                <option key={dateSlot.label} value={dateSlot.label}>
                  {dateSlot.label}
                </option>
              ))}
            </select>
            <span className={`${scope}-selectFocus`} />
          </label>
          <label className={`${scope}-selectWrapper`}>
            <span className={`${scope}-labelInvisible`}>Time</span>
            <select
              className={`${scope}-select`}
              onChange={onTimeChange}
              ref={ref}
              required={props.required}
              value={props.value ? dayjs(props.value).format('HH:mm') : ''}
            >
              {!isTimeSelected && <option disabled hidden value="" />}
              {timeOptions.map((timeOption) => (
                <option key={timeOption.label} value={timeOption.label}>
                  {timeOption.label}
                </option>
              ))}
            </select>
            <span className={`${scope}-selectFocus`} />
          </label>
        </div>
      </div>
    );
  },
);

export default DateTimeSelect;

interface DateSlotNormalized {
  label: string;
  timeSlots: TimeSlotNormalized[];
}

interface TimeSlotNormalized {
  label: string;
  value: Date;
}
