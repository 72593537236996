import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { buildRoutePath } from '../../../App';
import Button from '../../../components/Button';
import DiscoverLink from '../../../components/DiscoverLink';
import { ReactComponent as ChevronBottom } from '../../../icons/chevron-bottom.svg';
import { SectionProps } from '../Main';
import slogan from './slogan.svg';
import vertAnimated from './vert-animated.gif';

export default function Phase2SectionIntro(props: SectionProps) {
  const scope = 'Phase2SectionIntro';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const className = [scope, props.className].filter(Boolean).join(' ');

  function navigateForward() {
    navigate(buildRoutePath(['..', '2']), { relative: 'path' });
  }

  return (
    <section className={className}>
      <div className={`${scope}-gradient`}>
        <div className={`${scope}-body`}>
          <div className={`${scope}-vertContainer`}>
            <img
              alt="VERT"
              className={`${scope}-vertImage`}
              src={vertAnimated}
            />
            <img
              alt="Born virtually — shaped by you"
              className={`${scope}-vertSlogan`}
              src={slogan}
            />
          </div>
        </div>
        <div className={`${scope}-footer`}>
          <div className={`${scope}-footerContentCenter`}>
            <Button
              Icon={null}
              className={`${scope}-buttonJoin`}
              onClick={navigateForward}
              text={t('join-experience')}
              type="button"
            />
            <ChevronBottom className={`${scope}-arrowBottom`} />
          </div>
          <div className={`${scope}-footerContentRight`}>
            <DiscoverLink className={`${scope}-buttonDiscover`} />
          </div>
        </div>
      </div>
    </section>
  );
}
