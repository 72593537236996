import {
  RefObject,
  SyntheticEvent,
  TransitionEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { buildRoutePath } from '../../App';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import MobileMenu from '../../components/MobileMenu';
import SocialLinks from '../../components/SocialLinks';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';
import { isValidLocale } from '../../i18n';
import Phase2Header from './Header';
import Phase2ScrollPager from './ScrollPager';
import Phase2SectionCollection from './SectionCollection';
import Phase2SectionIntro from './SectionIntro';
import Phase2SectionRegistration from './SectionRegistration';
import Phase2SectionRetailEvent from './SectionRetailEvent';
import Phase2SectionTrailer from './SectionTrailer';
import './Main.scss';
import Phase2VideoPlayer from './VideoPlayer';
import mobileMenuLinks from './mobileMenuLinks';

export interface Props {
  className?: string;
}

export interface SectionProps {
  active: boolean;
  className?: string;
  sectionIndex: number;
  sectionsContainer: RefObject<HTMLElement>;
  sectionsCount: number;
}

export const sections = [
  Phase2SectionIntro,
  Phase2SectionTrailer,
  Phase2SectionRegistration,
  Phase2SectionCollection,
  Phase2SectionRetailEvent,
] as const;

export default function Phase2Main(props: Props) {
  const scope = 'Phase2Main';
  const { page: currentPage, locale } = useLoaderData() as RouteParams;
  const bodyRef = useRef<HTMLDivElement>(null);
  const debounceTimeoutRef = useRef<number | null>(null);
  const isScrolling = useRef(false);
  const isForceScrolling = useRef(false);
  const navigate = useNavigate();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const systemDevice = useSystemDevice();
  const { i18n, t } = useTranslation();

  const className = [
    scope,
    isVideoVisible && `${scope}--with-video`,
    props.className,
  ]
    .filter(Boolean)
    .join(' ');

  const scrollToCurrentSection = useCallback(() => {
    if (!bodyRef.current) {
      return;
    }
    const targetOffset = bodyRef.current.clientHeight * (currentPage - 1);
    if (bodyRef.current.scrollTop !== targetOffset) {
      isForceScrolling.current = true;
      bodyRef.current.scrollTo({
        behavior: 'smooth',
        top: targetOffset,
      });
    }
  }, [currentPage]);

  function onVideoTransitionEnd(event: TransitionEvent<HTMLElement>) {
    if (
      event.target === event.currentTarget &&
      event.propertyName === 'opacity' &&
      !isVideoVisible
    ) {
      event.currentTarget.hidden = true;
    }
  }

  function hideVideo() {
    setIsVideoVisible(false);
  }

  function toggleMenuVisibility() {
    setIsMenuVisible((value) => !value);
  }

  function navigateToScrolledPosition(container: HTMLDivElement) {
    const page = Math.round(container.scrollTop / window.innerHeight) + 1;
    if (page !== currentPage && !isForceScrolling.current) {
      const path = buildRoutePath(['..', page]);
      navigate(path, { relative: 'path', replace: true });
    }
  }

  function onScroll(event: SyntheticEvent<HTMLDivElement>) {
    const container = event.currentTarget;
    isScrolling.current = true;
    navigateToScrolledPosition(container);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(function finishScrolling() {
      isForceScrolling.current = false;
      isScrolling.current = false;
      // In case if user starts to scroll immediately after navigation
      navigateToScrolledPosition(container);
    }, 100);
  }

  useEffect(() => {
    if (i18n.language !== locale) {
      void i18n.changeLanguage(locale);
    }
  }, [i18n, locale]);

  useEffect(() => {
    if (
      (isScrolling.current && !isForceScrolling.current) ||
      !bodyRef.current
    ) {
      return;
    }
    scrollToCurrentSection();
  }, [scrollToCurrentSection]);

  return (
    <div className={className}>
      <div className={`${scope}-body`} onScroll={onScroll} ref={bodyRef}>
        {sections.map((SectionComponent, index) => (
          <SectionComponent
            active={currentPage - 1 === index}
            className={`${scope}-section`}
            key={index}
            sectionIndex={index}
            sectionsContainer={bodyRef}
            sectionsCount={sections.length}
          />
        ))}
      </div>

      {systemDevice === SYSTEM_DEVICE.desktop ? (
        <>
          <div className={`${scope}-pagerContainer`}>
            <Phase2ScrollPager numberOfPages={sections.length} />
          </div>

          <SocialLinks className={`${scope}-socialLinks`} />

          <LanguageSwitcher className={`${scope}-languageSwitcher`} phase="2" />
        </>
      ) : (
        <MobileMenu
          className={`${scope}-mobileMenu`}
          links={mobileMenuLinks(t)}
          onLinkClick={toggleMenuVisibility}
          phase="2"
          visible={isMenuVisible}
        />
      )}

      <Phase2Header
        className={`${scope}-header`}
        menuVisible={isMenuVisible}
        onMenuButtonClick={toggleMenuVisibility}
      />

      <Phase2VideoPlayer
        className={`${scope}-video`}
        onTransitionEnd={onVideoTransitionEnd}
        onVideoEnded={hideVideo}
      />
    </div>
  );
}

export function loader({ params }: LoaderFunctionArgs): RouteParams {
  const page = parseInt(params.page ?? '1');
  const locale = params.locale ?? '';

  if (!isValidPage(page) || !isValidLocale(locale)) {
    throw new Response('', {
      status: 404,
      statusText: 'Not Found',
    });
  }

  return { page, locale };
}

export function isValidPage(page: string | number) {
  const numberPage = typeof page === 'string' ? parseInt(page) : page;
  return (
    numberPage >= 1 &&
    numberPage <= sections.length &&
    numberPage === Math.floor(numberPage)
  );
}

export interface RouteParams {
  locale: string;
  page: number;
}
