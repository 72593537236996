import { TFunction } from 'i18next';
import { buildRoutePath } from '../../App';

export default function (t: TFunction<'translation'>) {
  return [
    {
      path: buildRoutePath(['..', '3']),
      text: t('metamorph-collection'),
    },
    {
      path: buildRoutePath(['..', '4']),
      text: `NFT ${t('collection') as string}`,
    },
    {
      path: buildRoutePath(['..', '5']),
      text: `${t('metaverse-experience') as string}`,
    },
    {
      path: buildRoutePath(['..', '6']),
      text: t('event-gallery'),
    },
  ];
}
