import React, {
  HTMLAttributes,
  MouseEvent,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { ReactComponent as Volume } from '../../../icons/volume.svg';

export interface Props extends HTMLAttributes<HTMLElement> {
  className?: string;
  onVideoEnded?: () => void;
}

export default function Phase2VideoPlayer(props: Props) {
  const scope = 'Phase2VideoPlayer';
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(true);
  const { t } = useTranslation();
  const { className: propsClassName, onVideoEnded, ...elementProps } = props;
  const className = [scope, isMuted && `${scope}--muted`, propsClassName]
    .filter(Boolean)
    .join(' ');

  function onVideoClick(event: MouseEvent<HTMLVideoElement>) {
    if (event.target === event.currentTarget && event.currentTarget.paused) {
      void event.currentTarget.play();
    }
  }

  function onVolumeChange(event: SyntheticEvent<HTMLVideoElement>) {
    setIsMuted(event.currentTarget.muted || event.currentTarget.volume === 0);
  }

  function skipVideo() {
    videoRef.current?.pause();
    onVideoEnded?.();
  }

  function toggleMuted() {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.muted = !videoRef.current.muted;
    if (!videoRef.current.muted && videoRef.current.volume === 0) {
      videoRef.current.volume = 1;
    }
  }

  return (
    <figure className={className} {...elementProps}>
      <div className={`${scope}-body`}>
        <video
          autoPlay
          className={`${scope}-video`}
          muted={isMuted}
          onClick={onVideoClick}
          onEnded={onVideoEnded}
          onVolumeChange={onVolumeChange}
          playsInline
          preload="auto"
          ref={videoRef}
        >
          <source
            src={`${process.env.PUBLIC_URL}/intro.mp4`}
            type="video/mp4"
          />
        </video>
        <div className={`${scope}-controls`}>
          <Button
            Icon={Volume}
            aria-label={t('mute')}
            className={`${scope}-buttonMute`}
            onClick={toggleMuted}
            type="button"
          />
          <Button
            className={`${scope}-buttonSkip`}
            onClick={skipVideo}
            type="button"
          >
            {t('skip')}
          </Button>
        </div>
      </div>
    </figure>
  );
}
