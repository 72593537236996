import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../App';
import { getDefaultLocale, isValidLocale } from '../../i18n';
import { isValidPage } from './Main';

export default function Phase2Error() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let path = location.pathname;
    if (path.startsWith('/')) {
      path = path.slice(1);
    }
    const pathSegments = path.split('/').slice(-2);
    const newPathSegments = pathSegments.slice();

    if (!isValidLocale(pathSegments[0])) {
      newPathSegments[0] = getDefaultLocale();
    }

    if (!isValidPage(pathSegments[1])) {
      newPathSegments[1] = '1';
    }

    navigate(buildRoutePath(['..', phasePaths['2'], ...newPathSegments]), {
      replace: true,
    });
  }, [location.pathname, navigate]);
  return null;
}
