import { ReactNode, useState } from 'react';
import './ContentPage.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Link, LoaderFunctionArgs } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../App';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import LegalLinks from '../../components/LegalLinks';
import MadeBySoil from '../../components/MadeBySoil';
import MobileMenu from '../../components/MobileMenu';
import SocialLinks from '../../components/SocialLinks';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';
import { isValidLocale } from '../../i18n';
import Phase3Header from './Header';
import mobileMenuLinks from './mobileMenuLinks';

export interface Props {
  className?: string;
  children?: ReactNode;
}

export default function Phase3ContentPage(props: Props) {
  const scope = 'Phase3ContentPage';
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const systemDevice = useSystemDevice();
  const { i18n, t } = useTranslation();
  const className = [
    scope,
    isMenuVisible && `${scope}--menuVisible`,
    props.className,
  ]
    .filter(Boolean)
    .join(' ');

  function toggleMenuVisibility() {
    setIsMenuVisible((value) => !value);
  }

  return (
    <div className={className}>
      <Phase3Header
        className={`${scope}-header`}
        menuVisible={isMenuVisible}
        onMenuButtonClick={toggleMenuVisibility}
      />
      <div className={`${scope}-content`}>{props.children}</div>
      <div className={`${scope}-footer`}>
        <div className={`${scope}-footerLinksAndLanguage`}>
          <SocialLinks className={`${scope}-socialLinks`} />
          <div className={`${scope}-footerLegalAndLanguage`}>
            <LegalLinks phase="3" />
            <LanguageSwitcher
              className={`${scope}-languageSwitcher`}
              phase="3"
            />
          </div>
        </div>
        <div className={`${scope}-footerDisclaimer`}>
          <Trans i18nKey="phase2-disclaimer-text">
            <Link
              to={buildRoutePath(
                [phasePaths['3'], i18n.language, 'terms'],
                true,
              )}
            />
          </Trans>
        </div>
        <MadeBySoil className={`${scope}-footerMadeBy`} />
      </div>
      {systemDevice === SYSTEM_DEVICE.mobile && (
        <MobileMenu
          className={`${scope}-mobileMenu`}
          links={mobileMenuLinks(t)}
          onLinkClick={toggleMenuVisibility}
          phase="3"
          visible={isMenuVisible}
        />
      )}
    </div>
  );
}

export function loader({ params }: LoaderFunctionArgs): RouteParams {
  const locale = params.locale ?? '';

  if (!isValidLocale(locale)) {
    throw new Response('', {
      status: 404,
      statusText: 'Not Found',
    });
  }

  return { locale };
}

export interface RouteParams {
  locale: string;
}
