import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './index.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../../App';
import Button from '../../../components/Button';
import LanguageSwitcher from '../../../components/LanguageSwitcher';
import LegalLinks from '../../../components/LegalLinks';
import MadeBySoil from '../../../components/MadeBySoil';
import SocialLinks from '../../../components/SocialLinks';
import { SYSTEM_DEVICE, useSystemDevice } from '../../../hooks/useSystemDevice';
import { SectionProps } from '../Main';
import SectionWatchesDesktop from './Desktop';
import SectionWatchesMobile from './Mobile';
import Watches from './watches.json';

const BLACK_SILVER_TEXT = 'black-silver';

export interface WatchItem {
  imagePath: string;
  color: string;
  link: string;
}

interface Props extends SectionProps {
  isSeparatePage?: boolean;
}

export default function Phase3SectionWatches(props: Props) {
  const scope = 'Phase3SectionWatches';

  const isSeparatePage = props.isSeparatePage || false;

  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const className = [scope, props.className].filter(Boolean).join(' ');

  const handleSlideChange = (index: number) => {
    setActiveIndex(index);
  };

  const systemDevice = useSystemDevice();

  function openLink() {
    window.open(
      'https://www.diesel.com/shop/category/diesel-man-marketing-metamorph',
      '_blank',
    );
  }

  const blackSilverItemIndex = Watches.findIndex(
    (watch) => watch.color === BLACK_SILVER_TEXT,
  );

  return (
    <section className={`${className} ${isSeparatePage ? 'full-height' : ''}`}>
      <div className={`${scope}-gradient`}>
        {systemDevice === SYSTEM_DEVICE.desktop ? (
          <SectionWatchesDesktop
            activeIndex={activeIndex}
            watches={Watches}
            handleSlideChange={handleSlideChange}
            blackSilverItemIndex={blackSilverItemIndex}
            isSeparatePage={isSeparatePage}
          />
        ) : (
          <SectionWatchesMobile
            activeIndex={activeIndex}
            watches={Watches}
            handleSlideChange={handleSlideChange}
            blackSilverItemIndex={blackSilverItemIndex}
            isSeparatePage={isSeparatePage}
          />
        )}

        {isSeparatePage ? (
          <>
            <div className={`${scope}-footerContentCenter`}>
              <Button
                className={`${scope}-orderNow`}
                onClick={openLink}
                text={t('order-now')}
                type="button"
              />
            </div>
            <div className={`${scope}-footerSeparatePage `}>
              <div className={`${scope}-footerLinksAndLanguage`}>
                <SocialLinks className={`${scope}-socialLinks`} />
                <div className={`${scope}-footerLegalAndLanguage`}>
                  <LegalLinks phase="2" />
                  <LanguageSwitcher
                    className={`${scope}-languageSwitcher`}
                    phase="2"
                  />
                </div>
              </div>
              <div className={`${scope}-footerDisclaimer`}>
                <Trans i18nKey="phase2-disclaimer-text">
                  <Link
                    to={buildRoutePath(
                      [phasePaths['2'], i18n.language, 'terms'],
                      true,
                    )}
                  />
                </Trans>
              </div>
              <MadeBySoil className={`${scope}-footerMadeBy`} />
            </div>
          </>
        ) : (
          <div className={`${scope}-footer`}>
            <div className={`${scope}-footerContentCenter`}>
              <Button
                className={`${scope}-orderNow`}
                onClick={openLink}
                text={t('order-now')}
                type="button"
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
