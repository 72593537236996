import React from 'react';
import './index.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../../App';
import LanguageSwitcher from '../../../components/LanguageSwitcher';
import LegalLinks from '../../../components/LegalLinks';
import MadeBySoil from '../../../components/MadeBySoil';
import SocialLinks from '../../../components/SocialLinks';
import { SectionProps } from '../Main';

export default function Phase2SectionRetailEvent(props: SectionProps) {
  const scope = 'Phase2SectionRetailEvent';
  const { i18n, t } = useTranslation();
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <section className={className}>
      <div className={`${scope}-gradient`}>
        <div className={`${scope}-body`}>
          <div className={`${scope}-content`}>
            <h2 className={`${scope}-title`}>{t('retail-title')}</h2>
            <div className={`${scope}-text`}>
              <Trans i18nKey="retail-text" />
            </div>
            <div className={`${scope}-continents`}>
              <div className={`${scope}-continent`}>
                <div className={`${scope}-continentName`}>
                  {t('retail-location-1-continent')}
                </div>
                <div className={`${scope}-locations`}>
                  <Trans i18nKey="retail-location-1-places" />
                </div>
              </div>
              <div className={`${scope}-continent`}>
                <div className={`${scope}-continentName`}>
                  {t('retail-location-2-continent')}
                </div>
                <div className={`${scope}-locations`}>
                  <Trans i18nKey="retail-location-2-places" />
                </div>
              </div>
              <div className={`${scope}-continent`}>
                <div className={`${scope}-continentName`}>
                  {t('retail-location-3-continent')}
                </div>
                <div className={`${scope}-locations`}>
                  <Trans i18nKey="retail-location-3-places" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${scope}-footer`}>
          <div className={`${scope}-footerLinksAndLanguage`}>
            <SocialLinks className={`${scope}-socialLinks`} />
            <div className={`${scope}-footerLegalAndLanguage`}>
              <LegalLinks phase="2" />
              <LanguageSwitcher
                className={`${scope}-languageSwitcher`}
                phase="2"
              />
            </div>
          </div>
          <div className={`${scope}-footerDisclaimer`}>
            <Trans i18nKey="phase2-disclaimer-text">
              <Link
                to={buildRoutePath(
                  [phasePaths['2'], i18n.language, 'terms'],
                  true,
                )}
              />
            </Trans>
          </div>
          <MadeBySoil className={`${scope}-footerMadeBy`} />
        </div>
      </div>
    </section>
  );
}
