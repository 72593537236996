import React from 'react';
import './index.scss';
import { Trans, useTranslation } from 'react-i18next';
import { SectionProps } from '../Main';
import Phase2SectionRegistrationForm from './Form';

export default function Phase2SectionRegistration(props: SectionProps) {
  const scope = 'Phase2SectionRegistration';
  const { t } = useTranslation();
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <section className={className}>
      <div className={`${scope}-gradient`}>
        <div className={`${scope}-body`}>
          <div className={`${scope}-areaDescription`}>
            <div className={`${scope}-descriptionTitle`}>
              {t('phase2-registration-title')}
            </div>
            <div className={`${scope}-descriptionText`}>
              <Trans i18nKey={'phase2-registration-text'} />
            </div>
          </div>
          <div className={`${scope}-areaForm`}>
            <Phase2SectionRegistrationForm className={`${scope}-form`} />
          </div>
        </div>
      </div>
    </section>
  );
}
