import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../routes/Root';
import Button from './Button';

export interface Props {
  className?: string;
}

export default function PrivacyPolicy(props: Props) {
  const scope = 'PrivacyPolicy';
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);

  const className = [scope, props.className].filter(Boolean).join(' ');

  function showConsent() {
    globalContext.setIsConsentVisible(true);
  }

  return (
    <div className={className}>
      <Button onClick={showConsent}>{t('manage-tracking')}</Button>
      <h1>Privacy policy</h1>
      <p>diesel.vert.com</p>

      <h2>Preamble</h2>
      <p>
        With the following privacy policy we would like to inform you which
        types of your personal data (hereinafter also abbreviated as ”data”) we
        process for which purposes and in which scope. The privacy statement
        applies to all processing of personal data carried out by us, on this
        website. The site is fully managed and controlled by Artificial Rome
        GmbH, Ritterstr. 2, 10969 Berlin who will act as Data Controller.
      </p>

      <p>The terms used are not gender-specific.</p>

      <h2>Table of contents</h2>
      <p>
        Preamble
        <br />
        Controller
        <br />
        Overview of processing operations
        <br />
        Legal Bases for the Processing
        <br />
        Security Precautions
        <br />
        Transmission and Disclosure of Personal Data
        <br />
        Data Processing in Third Countries
        <br />
        Use of Cookies
        <br />
        Commercial Services
        <br />
        Payment Service Provider
        <br />
        Provision of online services and web hosting
        <br />
        Music & Podcasts
        <br />
        Job Application Process
        <br />
        Cloud Services
        <br />
        Newsletter and Broadcast Communication
        <br />
        Sweepstakes and Contests
        <br />
        Onlinemarketing
        <br />
        Profiles in Social Networks
        <br />
        Plugins and embedded functions and content
        <br />
        Erasure of data
        <br />
        Changes and Updates to the Privacy Policy
        <br />
        Rights of Data Subjects
        <br />
        Terminology and Definitions
        <br />
      </p>

      <h2>Date protection officer contact address:</h2>
      <p>
        <a href="mailto:hello@artificialrome.com">hello@artificialrome.com</a>
      </p>

      <h2>Overview of processing operations</h2>
      <p>
        The following section summarizes the types of data processed, the
        purposes for which they are processed and the concerned data subjects.
      </p>

      <h2>Categories of processed data</h2>
      <p>
        Inventory data (e.g. names).
        <br />
        Contact data (e.g. e-mail).
      </p>

      <h2>Categories of data subjects</h2>
      <p>
        Customers.
        <br />
        Users (e.g. website visitors, users of online services).
      </p>

      <h2>Purposes of processing</h2>
      <p>
        Direct marketing (e.g. by e-mail or postal).
        <br />
        Remarketing.
        <br />
        Web Analytics (e.g. access statistics, recognition of returning
        visitors).
        <br />
        Security measures.
        <br />
        Contractual services and support.
        <br />
        Managing and responding to inquiries.
      </p>

      <h2>Legal bases for the processing</h2>
      <p>
        In the following we inform you about the legal basis of the General Data
        Protection Regulation (GDPR), on the basis of which we process personal
        data. Please note that, in addition to the regulations of the GDPR, the
        national data protection regulations may apply in your country or in our
        country of residence or domicile.
      </p>

      <p>
        Consent (Article 6 (1) (a) GDPR) – The data subject has given consent to
        the processing of his or her personal data for one or more specific
        purposes. Performance of a contract and prior requests (Article 6 (1)
        (b) GDPR) – Performance of a contract to which the data subject is party
        or in order to take steps at the request of the data subject prior to
        entering into a contract.
        <br />
        Compliance with a legal obligation (Article 6 (1) (c) GDPR) – Processing
        is necessary for compliance with a legal obligation to which the
        controller is subject. Legitimate Interests (Article 6 (1) (f) GDPR) –
        Processing is necessary for the purposes of the legitimate interests
        pursued by the controller or by a third party, except where such
        interests are overridden by the interests or fundamental rights and
        freedoms of the data subject which require protection of personal data.
      </p>

      <h2>Security precautions</h2>
      <p>
        We take appropriate technical and organisational measures in accordance
        with the legal requirements, taking into account the state of the art,
        the costs of implementation and the nature, scope, context and purposes
        of processing as well as the risk of varying likelihood and severity for
        the rights and freedoms of natural persons, in order to ensure a level
        of security appropriate to the risk.
      </p>

      <p>
        The measures include, in particular, safeguarding the confidentiality,
        integrity and availability of data by controlling physical and
        electronic access to the data as well as access to, input, transmission,
        securing and separation of the data. In addition, we have established
        procedures to ensure that data subjects’ rights are respected, that data
        is erased, and that we are prepared to respond to data threats rapidly.
        Furthermore, we take the protection of personal data into account as
        early as the development or selection of hardware, software and service
        providers, in accordance with the principle of privacy by design and
        privacy by default.
      </p>

      <h2>Transmission and disclosure of personal data</h2>
      <p>
        In the context of our processing of personal data, it may happen that
        the data is transferred to other places, companies or persons or that it
        is disclosed to them. Recipients of this data may include, for example,
        payment institutions within the context of payment transactions, service
        providers commissioned with IT tasks or providers of services and
        content that are embedded in a website. In such a case, the legal
        requirements will be respected and in particular corresponding contracts
        or agreements, which serve the protection of your data, will be
        concluded with the recipients of your data.
      </p>

      <h2>Data processing in third countries</h2>
      <p>
        If we process data in a third country (i.e. outside the European Union
        (EU), the European Economic Area (EEA)) or the processing takes place in
        the context of the use of third party services or disclosure or transfer
        of data to other persons, bodies or companies, this will only take place
        in accordance with the legal requirements.
      </p>

      <p>
        Subject to express consent or transfer required by contract or law, we
        process or have processed the data only in third countries with a
        recognised level of data protection, which includes US processors
        certified under the “Privacy Shield” or on the basis of special
        guarantees, such as a contractual obligation through so-called standard
        protection clauses of the EU Commission, the existence of certifications
        or binding internal data protection regulations (Article 44 to 49 GDPR,
        information page of the EU Commission:{' '}
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_en"
          rel="noreferrer"
          target="_blank"
        >
          https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_en
        </a>
        ).
      </p>

      <h2>Use of cookies</h2>
      <p>
        Cookies are text files that contain data from visited websites or
        domains and are stored by a browser on the user’s computer. A cookie is
        primarily used to store information about a user during or after his
        visit within an online service. The information stored can include, for
        example, the language settings on a website, the login status, a
        shopping basket or the location where a video was viewed. The term
        “cookies” also includes other technologies that fulfil the same
        functions as cookies (e.g. if user information is stored using
        pseudonymous online identifiers, also referred to as “user IDs”).
      </p>

      <p>The following types and functions of cookies are distinguished:</p>

      <p>
        Temporary cookies (also: session cookies): Temporary cookies are deleted
        at the latest after a user has left an online service and closed his
        browser. Permanent cookies: Permanent cookies remain stored even after
        closing the browser. For example, the login status can be saved or
        preferred content can be displayed directly when the user visits a
        website again. The interests of users who are used for range measurement
        or marketing purposes can also be stored in such a cookie.
        <br />
        First-Party-Cookies: First-Party-Cookies are set by ourselves.
        <br />
        Third party cookies: Third party cookies are mainly used by advertisers
        (so-called third parties) to process user information.
        <br />
        Necessary (also: essential) cookies: Cookies can be necessary for the
        operation of a website (e.g. to save logins or other user inputs or for
        security reasons). Statistics, marketing and personalisation cookies:
        Cookies are also generally used to measure a website’s reach and when a
        user’s interests or behaviour (e.g. viewing certain content, using
        functions, etc.) are stored on individual websites in a user profile.
        Such profiles are used, for example, to display content to users that
        corresponds to their potential interests. This procedure is also
        referred to as “tracking”, i.e. tracking the potential interests of
        users. . If we use cookies or “tracking” technologies, we will inform
        you separately in our privacy policy or in the context of obtaining
        consent.
      </p>

      <p>
        Information on legal basis: The legal basis on which we process your
        personal data with the help of cookies depends on whether we ask you for
        your consent. If this applies and you consent to the use of cookies, the
        legal basis for processing your data is your declared consent.
        Otherwise, the data processed with the help of cookies will be processed
        on the basis of our legitimate interests (e.g. in a business operation
        of our online service and its improvement) or, if the use of cookies is
        necessary to fulfill our contractual obligations.
      </p>

      <p>
        General information on Withdrawal of consent and objection (Opt-Out):
        Respective of whether processing is based on consent or legal
        permission, you have the option at any time to object to the processing
        of your data using cookie technologies or to revoke consent
        (collectively referred to as “opt-out”). You can initially explain your
        objection using the settings of your browser, e.g. by deactivating the
        use of cookies (which may also restrict the functionality of our online
        services). An objection to the use of cookies for online marketing
        purposes can be raised for a large number of services, especially in the
        case of tracking, via the US website{' '}
        <a
          href="http://www.aboutads.info/choices/"
          rel="noreferrer"
          target="_blank"
        >
          http://www.aboutads.info/choices/
        </a>{' '}
        or the EU website{' '}
        <a
          href="http://www.youronlinechoices.com/"
          rel="noreferrer"
          target="_blank"
        >
          http://www.youronlinechoices.com/
        </a>{' '}
        or generally on{' '}
        <a href="http://optout.aboutads.info" rel="noreferrer" target="_blank">
          http://optout.aboutads.info
        </a>
        . Further information on the possibility of opposition is also given to
        Se in the context of the information on the respective processing
        operations in this privacy policy.
      </p>

      <p>
        Processing Cookie Data on the Basis of Consent: Before we process or
        have processed data within the context of the usage of cookies, we ask
        the users for their consent, which can be revoked at any time. Before
        the consent has not been given, we may use cookies that are necessary
        for the operation of our online services. Their use is based on our
        interest and the user’s interest in the expected functionality of our
        online services.
      </p>

      <p>
        Processed data types: Usage data (e.g. websites visited, interest in
        content, access times), Meta/communication data (e.g. device
        information, IP addresses). Data subjects: Users (e.g. website visitors,
        users of online services).
        <br />
        Legal Basis: Consent (Article 6 (1) (a) GDPR), Legitimate Interests
        (Article 6 (1) (f) GDPR).
      </p>

      <h2>Commercial services</h2>
      <p>
        We process data of our contractual and business partners, e.g. customers
        and interested parties (collectively referred to as “contractual
        partners”) within the context of contractual and comparable legal
        relationships as well as associated actions and communication with the
        contractual partners or pre-contractually, e.g. to answer inquiries.
      </p>

      <p>
        We process this data in order to fulfil our contractual obligations,
        safeguard our rights and for the purposes of the administrative tasks
        associated with this data and the business-related organisation. We will
        only pass on the data of the contractual partners within the scope of
        the applicable law to third parties insofar as this is necessary for the
        aforementioned purposes or for the fulfilment of legal obligations or
        with the consent of the contractual partners (e.g. telecommunications,
        transport and other auxiliary services as well as subcontractors, banks,
        tax and legal advisors, payment service providers or tax authorities).
        The contractual partners will be informed about further processing, e.g.
        for marketing purposes, as part of this privacy policy.
      </p>

      <p>
        Which data are necessary for the aforementioned purposes, we inform the
        contracting partners before or in the context of the data collection,
        e.g. in on-line forms by special marking (e.g. colors), and/or symbols
        (e.g. asterisks or the like), or personally.
      </p>

      <p>
        We delete the data after expiry of statutory warranty and comparable
        obligations, i.e. in principle after expiry of 12 months. In the case of
        data disclosed to us by the contractual partner within the context of an
        assignment, we delete the data in accordance with the specifications of
        the assignment, in general after the end of the assignment.
      </p>

      <p>
        If we use third-party providers or platforms to provide our services,
        the terms and conditions and privacy policies of the respective
        third-party providers or platforms shall apply in the relationship
        between the users and the providers.
      </p>

      <p>
        Economic Analyses and Market Research: For economic reasons and in order
        to be able to recognise market trends, wishes of contractual partners
        and users, we analyse the data available to us on business transactions,
        contracts, enquiries, etc., whereby the persons concerned may include
        contractual partners, interested parties, customers, visitors and users
        of our online service.
      </p>

      <p>
        The analyses are carried out for the purpose of business evaluations,
        marketing and market research (for example, to determine customer groups
        with different characteristics). If available, we can take into account
        the profiles of registered users with information, e.g. on the services
        they have used. The analyses serve us alone and are not disclosed
        externally unless they are anonymous analyses with summarised, i.e.
        anonymised values. Furthermore, we respect the privacy of the users and
        process the data for analysis purposes as pseudonymously as possible and
        if possible anonymously (e.g. as summarized data).
      </p>

      <p>
        Processed data types: Inventory data (e.g. names, addresses), Contact
        data (e.g. e-mail, telephone numbers), Usage data (e.g. websites
        visited, interest in content, access times), Meta/communication data
        (e.g. device information, IP addresses). Data subjects: Prospective
        customers, Business and contractual partners, Customers.
        <br />
        Purposes of Processing: Contractual services and support, contact
        requests and communication, Office and organisational procedures,
        Managing and responding to inquiries, Conversion Tracking,
        Interest-based and behavioral marketing, Profiling (Creating user
        profiles), Security measures.
        <br />
        Legal Basis: Performance of a contract and prior requests (Article 6 (1)
        (b) GDPR), Compliance with a legal obligation (Article 6 (1) (c) GDPR),
        Legitimate Interests (Article 6 (1) (f) GDPR).
      </p>

      <h2>Provision of online services and web hosting</h2>
      <p>
        In order to provide our online services securely and efficiently, we use
        the services of one or more web hosting providers from whose servers (or
        servers they manage) the online services can be accessed. For these
        purposes, we may use infrastructure and platform services, computing
        capacity, storage space and database services, as well as security and
        technical maintenance services.
      </p>

      <p>
        The data processed within the framework of the provision of the hosting
        services may include all information relating to the users of our online
        services that is collected in the course of use and communication. This
        regularly includes the IP address, which is necessary to be able to
        deliver the contents of online services to browsers, and all entries
        made within our online services or from websites.
      </p>

      <p>
        E-mail Sending and Hosting: The web hosting services we use also include
        sending, receiving and storing e-mails. For these purposes, the
        addresses of the recipients and senders, as well as other information
        relating to the sending of e-mails (e.g. the providers involved) and the
        contents of the respective e-mails are processed. The above data may
        also be processed for SPAM detection purposes. Please note that e-mails
        on the Internet are generally not sent in encrypted form. As a rule,
        e-mails are encrypted during transport, but not on the servers from
        which they are sent and received (unless a so-called end-to-end
        encryption method is used). We can therefore accept no responsibility
        for the transmission path of e-mails between the sender and reception on
        our server.
      </p>

      <p>
        Processed data types: Content data (e.g. text input, photographs,
        videos), Usage data (e.g. websites visited, interest in content, access
        times), Meta/communication data (e.g. device information, IP addresses).
        <br />
        Data subjects: Users (e.g. website visitors, users of online services).
        <br />
        Legal Basis: Legitimate Interests (Article 6 (1) (f) GDPR).
      </p>

      <h2>Cloud services</h2>
      <p>
        We use Internet-accessible software services (so-called “cloud
        services”, also referred to as “Software as a Service”) provided on the
        servers of its providers for the following purposes: document storage
        and administration, calendar management, e-mail delivery, spreadsheets
        and presentations, exchange of documents, content and information with
        specific recipients or publication of websites, forms or other content
        and information, as well as chats and participation in audio and video
        conferences.
      </p>

      <p>
        Within this framework, personal data may be processed and stored on the
        provider’s servers insofar as this data is part of communication
        processes with us or is otherwise processed by us in accordance with
        this privacy policy. This data may include in particular master data and
        contact data of data subjects, data on processes, contracts, other
        proceedings and their contents. Cloud service providers also process
        usage data and metadata that they use for security and service
        optimization purposes.
      </p>

      <p>
        If we use cloud services to provide documents and content to other users
        or publicly accessible websites, forms, etc., providers may store
        cookies on users’ devices for web analysis or to remember user settings
        (e.g. in the case of media control).
      </p>

      <p>
        Information on legal basis – If we ask for permission to use cloud
        services, the legal basis for processing data is consent. Furthermore,
        their use can be a component of our (pre)contractual services, provided
        that the use of cloud services has been agreed in this context.
        Otherwise, user data will be processed on the basis of our legitimate
        interests (i.e. interest in efficient and secure administrative and
        collaboration processes).
      </p>

      <p>
        Processed data types: Inventory data (e.g. names, addresses), Contact
        data (e.g. e-mail, telephone numbers), Content data (e.g. text input,
        photographs, videos), Usage data (e.g. websites visited, interest in
        content, access times), Meta/communication data (e.g. device
        information, IP addresses).
        <br />
        Data subjects: Customers, Employees (e.g. Employees, job applicants),
        Prospective customers, Communication partner (Recipients of e-mails,
        letters, etc.). Purposes of Processing: Office and organisational
        procedures.
        <br />
        Legal Basis: Consent (Article 6 (1) (a) GDPR), Performance of a contract
        and prior requests (Article 6 (1) (b) GDPR), Legitimate Interests
        (Article 6 (1) (f) GDPR).
      </p>

      <p>Services and service providers being used:</p>

      <p>
        Google Cloud Services: Cloud storage services; Service provider: Google
        Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, ,
        parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA; Website:{' '}
        <a href="https://cloud.google.com/" rel="noreferrer" target="_blank">
          https://cloud.google.com/
        </a>
        ; Privacy Policy:{' '}
        <a
          href="https://www.google.com/policies/privacy"
          rel="noreferrer"
          target="_blank"
        >
          https://www.google.com/policies/privacy
        </a>
        , Security information:{' '}
        <a
          href="https://cloud.google.com/security/privacy"
          rel="noreferrer"
          target="_blank"
        >
          https://cloud.google.com/security/privacy
        </a>
        ; Privacy Shield (Safeguarding the level of data protection when
        processing data in the USA):{' '}
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&status=Aktive"
          rel="noreferrer"
          target="_blank"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&status=Aktive
        </a>
        .
      </p>

      <h2>Newsletter and broadcast communication</h2>
      <p>
        We send newsletters, e-mails and other electronic communications
        (hereinafter referred to as “newsletters”) only with the consent of the
        recipient or a legal permission. Insofar as the contents of the
        newsletter are specifically described within the framework of
        registration, they are decisive for the consent of the user. Otherwise,
        our newsletters contain information about our services and us.
      </p>

      <p>
        In order to subscribe to our newsletters, it is generally sufficient to
        enter your e-mail address. We may, however, ask you to provide a name
        for the purpose of contacting you personally in the newsletter or to
        provide further information if this is required for the purposes of the
        newsletter.
      </p>

      <p>
        Double opt-in procedure: The registration to our newsletter takes place
        in general in a so-called Double-Opt-In procedure. This means that you
        will receive an e-mail after registration asking you to confirm your
        registration. This confirmation is necessary so that no one can register
        with external e-mail addresses.
      </p>

      <p>
        The registrations for the newsletter are logged in order to be able to
        prove the registration process according to the legal requirements. This
        includes storing the login and confirmation times as well as the IP
        address. Likewise the changes of your data stored with the dispatch
        service provider are logged.
      </p>

      <p>
        Deletion and restriction of processing: We may store the unsubscribed
        email addresses for up to three years based on our legitimate interests
        before deleting them to provide evidence of prior consent. The
        processing of these data is limited to the purpose of a possible defense
        against claims. An individual deletion request is possible at any time,
        provided that the former existence of a consent is confirmed at the same
        time. In the case of an obligation to permanently observe an objection,
        we reserve the right to store the e-mail address solely for this purpose
        in a blacklist.
      </p>

      <p>
        Information on legal bases: The sending of the newsletter is based on
        the consent of the recipients or, if consent is not required, on the
        basis of our legitimate interests in direct marketing. Insofar as we
        engage a service provider for sending e-mails, this is done on the basis
        of our legitimate interests. The registration procedure is recorded on
        the basis of our legitimate interests for the purpose of demonstrating
        that it has been conducted in accordance with the law.
      </p>

      <p>
        Contents: Information about us, our services, promotions and offers.
      </p>

      <p>
        Performance measurement: The newsletters contain a so-called
        “web-beacon”, i.e. a pixel-sized file, which is retrieved from our
        server when the newsletter is opened or, if we use a mailing service
        provider, from its server. Within the scope of this retrieval, technical
        information such as information about the browser and your system, as
        well as your IP address and time of retrieval are first collected.
      </p>

      <p>
        This information is used for the technical improvement of our newsletter
        on the basis of technical data or target groups and their reading
        behaviour on the basis of their retrieval points (which can be
        determined with the help of the IP address) or access times. This
        analysis also includes determining whether newsletters are opened, when
        they are opened and which links are clicked. For technical reasons, this
        information can be assigned to the individual newsletter recipients. It
        is, however, neither our endeavour nor, if used, that of the shipping
        service provider to observe individual users. The evaluations serve us
        much more to recognize the reading habits of our users and to adapt our
        content to them or to send different content according to the interests
        of our users.
      </p>

      <p>
        The evaluation of the newsletter and the measurement of success is
        carried out, subject to the express consent of the user, on the basis of
        our legitimate interests for the purposes of using a user-friendly and
        secure newsletter system which serves both our business interests and
        the expectations of the user.
      </p>

      <p>
        A separate objection to the performance measurement is unfortunately not
        possible, in this case the entire newsletter subscription must be
        cancelled or objected to.
      </p>

      <p>
        Processed data types: Inventory data (e.g. names, addresses), Contact
        data (e.g. e-mail, telephone numbers), Meta/communication data (e.g.
        device information, IP addresses), Usage data (e.g. websites visited,
        interest in content, access times).
        <br />
        Data subjects: Communication partner (Recipients of e-mails, letters,
        etc.).
        <br />
        Purposes of Processing: Direct marketing (e.g. by e-mail or postal).
        <br />
        Legal Basis: Consent (Article 6 (1) (a) GDPR), Legitimate Interests
        (Article 6 (1) (f) GDPR).
        <br />
        Opt-Out: You can cancel the receipt of our newsletter at any time, i.e.
        revoke your consent or object to further receipt. You will find a link
        to cancel the newsletter either at the end of each newsletter or you can
        otherwise use one of the contact options listed above, preferably
        e-mail.
      </p>

      <p>Services and service providers being used:</p>

      <p>
        Mailchimp: Email marketing platform; Service provider: “Mailchimp”
        &mdash; Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000,
        Atlanta, GA 30308, USA; Website:{' '}
        <a href="https://mailchimp.com" rel="noreferrer" target="_blank">
          https://mailchimp.com
        </a>
        ; Privacy Policy:{' '}
        <a
          href="https://mailchimp.com/legal/privacy/"
          rel="noreferrer"
          target="_blank"
        >
          https://mailchimp.com/legal/privacy/
        </a>
        ; Privacy Shield (Safeguarding the level of data protection when
        processing data in the USA):{' '}
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active"
          rel="noreferrer"
          target="_blank"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active
        </a>
        .
      </p>

      <h2>Online marketing</h2>
      <p>
        We process personal data for the purposes of online marketing, which may
        include in particular the marketing of advertising space or the display
        of advertising and other content (collectively referred to as “Content”)
        based on the potential interests of users and the measurement of their
        effectiveness.
      </p>

      <p>
        For these purposes, so-called user profiles are created and stored in a
        file (so-called “cookie”) or similar procedure in which the relevant
        user information for the display of the aforementioned content is
        stored. This information may include, for example, content viewed,
        websites visited, online networks used, communication partners and
        technical information such as the browser used, computer system used and
        information on usage times. If users have consented to the collection of
        their sideline data, these can also be processed.
      </p>

      <p>
        The IP addresses of the users are also stored. However, we use provided
        IP masking procedures (i.e. pseudonymisation by shortening the IP
        address) to ensure the protection of the user’s by using a pseudonym. In
        general, within the framework of the online marketing process, no clear
        user data (such as e-mail addresses or names) is secured, but
        pseudonyms. This means that we, as well as the providers of online
        marketing procedures, do not know the actual identity of the users, but
        only the information stored in their profiles.
      </p>

      <p>
        The information in the profiles is usually stored in the cookies or
        similar memorizing procedures. These cookies can later, generally also
        on other websites that use the same online marketing technology, be read
        and analyzed for purposes of content display, as well as supplemented
        with other data and stored on the server of the online marketing
        technology provider.
      </p>

      <p>
        Exceptionally, clear data can be assigned to the profiles. This is the
        case, for example, if the users are members of a social network whose
        online marketing technology we use and the network links the profiles of
        the users in the aforementioned data. Please note that users may enter
        into additional agreements with the social network providers or other
        service providers, e.g. by consenting as part of a registration process.
      </p>

      <p>
        As a matter of principle, we only gain access to summarised information
        about the performance of our advertisements. However, within the
        framework of so-called conversion measurement, we can check which of our
        online marketing processes have led to a so-called conversion, i.e. to
        the conclusion of a contract with us. The conversion measurement is used
        alone for the performance analysis of our marketing activities.
      </p>

      <p>
        Information on legal basis: If we ask users for their consent (e.g. in
        the context of a so-called “cookie banner consent”), the legal basis for
        processing data for online marketing purposes is this consent.
        Otherwise, user data will be processed on the basis of our legitimate
        interests (i.e. interest in the analysis, optimisation and economic
        operation of our online services. In this context, we would also like to
        refer you to the information on the use of cookies in this privacy
        policy.
      </p>

      <p>
        Facebook Pixel: With the help of the Facebook pixel, Facebook is on the
        one hand able to determine the visitors of our online services as a
        target group for the presentation of ads (so-called “Facebook ads”).
        Accordingly, we use Facebook pixels to display Facebook ads placed by us
        only to Facebook users and within the services of partners cooperating
        with Facebook (so-called “audience network”{' '}
        <a
          href="https://www.facebook.com/audiencenetwork/"
          rel="noreferrer"
          target="_blank"
        >
          https://www.facebook.com/audiencenetwork/
        </a>{' '}
        ) who have shown an interest in our online services or who have certain
        characteristics (e.g. interests in certain topics or products that are
        determined on the basis of the websites visited) that we transmit to
        Facebook (so-called “custom audiences”). With the help of Facebook
        pixels, we also want to ensure that our Facebook ads correspond to the
        potential interest of users and do not appear annoying. The Facebook
        pixel also enables us to track the effectiveness of Facebook ads for
        statistical and market research purposes by showing whether users were
        referred to our website after clicking on a Facebook ad (known as
        “conversion tracking”).
      </p>

      <p>
        Processed data types: Usage data (e.g. websites visited, interest in
        content, access times), Meta/communication data (e.g. device
        information, IP addresses), Location data (Data that indicates the
        location of the end device of an end user).
        <br />
        Data subjects: Users (e.g. website visitors, users of online services).
        <br />
        Purposes of Processing: Targeting (e.g. profiling based on interests and
        behaviour, use of cookies), Remarketing, Conversion Tracking,
        Interest-based and behavioral marketing, Profiling (Creating user
        profiles), Conversion tracking (Measurement of the effectiveness of
        marketing activities).
        <br />
        Security measures: IP Masking (Pseudonymization of the IP address).
        <br />
        Legal Basis: Consent (Article 6 (1) (a) GDPR), Legitimate Interests
        (Article 6 (1) (f) GDPR).
        <br />
        Opt-Out: We refer to the privacy policies of the respective service
        providers and the possibilities for objection (so-called “opt-out”). If
        no explicit opt-out option has been specified, it is possible to
        deactivate cookies in the settings of your browser. However, this may
        restrict the functions of our online offer. We therefore recommend the
        following additional opt-out options, which are offered collectively for
        each area: a) Europe:{' '}
        <a
          href="https://www.youronlinechoices.eu"
          rel="noreferrer"
          target="_blank"
        >
          https://www.youronlinechoices.eu
        </a>
        . b) Canada:{' '}
        <a
          href="https://www.youradchoices.ca/choices"
          rel="noreferrer"
          target="_blank"
        >
          https://www.youradchoices.ca/choices
        </a>
        . c) USA:{' '}
        <a
          href="https://www.aboutads.info/choices"
          rel="noreferrer"
          target="_blank"
        >
          https://www.aboutads.info/choices
        </a>
        . d) Cross-regional:{' '}
        <a href="http://optout.aboutads.info" rel="noreferrer" target="_blank">
          http://optout.aboutads.info
        </a>
        .
      </p>

      <p>Services and service providers being used:</p>

      <p>
        Google Analytics: Online marketing and web analytics; Service provider:
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland,
        parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA; Website:{' '}
        <a
          href="https://marketingplatform.google.com/intl/en/about/analytics/"
          rel="noreferrer"
          target="_blank"
        >
          https://marketingplatform.google.com/intl/en/about/analytics/
        </a>
        ; Privacy Policy:{' '}
        <a
          href="https://policies.google.com/privacy"
          rel="noreferrer"
          target="_blank"
        >
          https://policies.google.com/privacy
        </a>
        ; Privacy Shield (Safeguarding the level of data protection when
        processing data in the USA):{' '}
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
          rel="noreferrer"
          target="_blank"
        >
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
        </a>
        ; Opt-Out: Opt-Out-Plugin:{' '}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=en"
          rel="noreferrer"
          target="_blank"
        >
          https://tools.google.com/dlpage/gaoptout?hl=en
        </a>
        , Settings for the Display of Advertisements:{' '}
        <a
          href="https://adssettings.google.com/authenticated"
          rel="noreferrer"
          target="_blank"
        >
          https://adssettings.google.com/authenticated
        </a>
        .
        <br />
        Facebook Pixel: Facebook-Pixel; Service provider:{' '}
        <a href="https://www.facebook.com" rel="noreferrer" target="_blank">
          https://www.facebook.com
        </a>
        , Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
        Dublin 2, Irland, parent company: Facebook, 1 Hacker Way, Menlo Park, CA
        94025, USA; Website:{' '}
        <a href="https://www.facebook.com" rel="noreferrer" target="_blank">
          https://www.facebook.com
        </a>
        ; Privacy Policy:{' '}
        <a
          href="https://www.facebook.com/about/privacy"
          rel="noreferrer"
          target="_blank"
        >
          https://www.facebook.com/about/privacy
        </a>
        ; Privacy Shield (Safeguarding the level of data protection when
        processing data in the USA):{' '}
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
          rel="noreferrer"
          target="_blank"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active
        </a>
        ; Opt-Out:{' '}
        <a
          href="https://www.facebook.com/settings?tab=ads"
          rel="noreferrer"
          target="_blank"
        >
          https://www.facebook.com/settings?tab=ads
        </a>
        .
        <br />
        LinkedIn: e.g. Insights Tag / Conversion tracking; Service provider:
        LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA;
        Website:{' '}
        <a href="https://www.linkedin.com" rel="noreferrer" target="_blank">
          https://www.linkedin.com
        </a>
        ; Security measures: IP Masking (Pseudonymization of the IP address);
        Privacy Policy:{' '}
        <a
          href="https://www.linkedin.com/legal/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          https://www.linkedin.com/legal/privacy-policy
        </a>
        , cookie policy:{' '}
        <a
          href="https://www.linkedin.com/legal/cookie_policy"
          rel="noreferrer"
          target="_blank"
        >
          https://www.linkedin.com/legal/cookie_policy
        </a>
        ; Privacy Shield (Safeguarding the level of data protection when
        processing data in the USA):{' '}
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active"
          rel="noreferrer"
          target="_blank"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active
        </a>
        ; Opt-Out:{' '}
        <a
          href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
          rel="noreferrer"
          target="_blank"
        >
          https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
        </a>
        .
        <br />
        SalesViewer®: This website uses SalesViewer® technology from
        SalesViewer® GmbH on the basis of the website operator’s legitimate
        interests (Section 6 paragraph 1 lit.f GDPR) in order to collect and
        save data on marketing, market research and optimisation purposes. In
        order to do this, a javascript based code, which serves to capture
        company-related data and according website usage. The data captured
        using this technology are encrypted in a non-retrievable one-way
        function (so-called hashing). The data is immediately pseudonymised and
        is not used to identify website visitors personallyThe data stored by
        Salesviewer will be deleted as soon as they are no longer required for
        their intended purpose and there are no legal obligations to retain
        them.
      </p>

      <p>
        The data recording and storage can be repealed at any time with
        immediate effect for the future, by clicking on{' '}
        <a
          href="https://www.salesviewer.com/opt-out"
          rel="noreferrer"
          target="_blank"
        >
          https://www.salesviewer.com/opt-out
        </a>{' '}
        in order to prevent SalesViewer® from recording your data. In this
        case, an opt-out cookie for this website is saved on your device. If you
        delete the cookies in the browser, you will need to click on this link
        again.
      </p>

      <h2>Profiles in social networks</h2>
      <p>
        We maintain online presences within social networks in order to
        communicate with the users active there or to offer information about us
        there.
      </p>

      <p>
        We would like to point out that user data may be processed outside the
        European Union. This may entail risks for users, e.g. by making it more
        difficult to enforce users’ rights. With regard to US providers
        certified under the Privacy Shield or offering comparable guarantees of
        a secure level of data protection, we would like to point out that they
        thereby commit themselves to comply with EU data protection standards.
      </p>

      <p>
        In addition, user data is usually processed within social networks for
        market research and advertising purposes. For example, user profiles can
        be created on the basis of user behaviour and the associated interests
        of users. The user profiles can then be used, for example, to place
        advertisements within and outside the networks which are presumed to
        correspond to the interests of the users. For these purposes, cookies
        are usually stored on the user’s computer, in which the user’s usage
        behaviour and interests are stored. Furthermore, data can be stored in
        the user profiles independently of the devices used by the users
        (especially if the users are members of the respective networs or will
        become members later on).
      </p>

      <p>
        For a detailed description of the respective processing operations and
        the opt-out options, please refer to the respective data protection
        declarations and information provided by the providers of the respective
        networks.
      </p>

      <p>
        Also in the case of requests for information and the exercise of rights
        of data subjects, we point out that these can be most effectively
        pursued with the providers. Only the providers have access to the data
        of the users and can directly take appropriate measures and provide
        information. If you still need help, please do not hesitate to contact
        us.
      </p>

      <p>
        Processed data types: Inventory data (e.g. names, addresses), Contact
        data (e.g. e-mail, telephone numbers), Content data (e.g. text input,
        photographs, videos), Usage data (e.g. websites visited, interest in
        content, access times), Meta/communication data (e.g. device
        information, IP addresses). Data subjects: Users (e.g. website visitors,
        users of online services). Purposes of Processing: contact requests and
        communication, Targeting (e.g. profiling based on interests and
        behaviour, use of cookies), Remarketing. Legal Basis: Legitimate
        Interests (Article 6 (1) (f) GDPR).
      </p>

      <h2>Plugins and embedded functions and content</h2>
      <p>
        Within our online services, we integrate functional and content elements
        that are obtained from the servers of their respective providers
        (hereinafter referred to as “third-party providers”). These may, for
        example, be graphics, videos or social media buttons as well as
        contributions (hereinafter uniformly referred to as “Content”).
      </p>

      <p>
        The integration always presupposes that the third-party providers of
        this content process the IP address of the user, since they could not
        send the content to their browser without the IP address. The IP address
        is therefore required for the presentation of these contents or
        functions. We strive to use only those contents, whose respective
        offerers use the IP address only for the distribution of the contents.
        Third parties may also use so-called pixel tags (invisible graphics,
        also known as “web beacons”) for statistical or marketing purposes. The
        “pixel tags” can be used to evaluate information such as visitor traffic
        on the pages of this website. The pseudonymous information may also be
        stored in cookies on the user’s device and may include technical
        information about the browser and operating system, referring websites,
        visit times and other information about the use of our website, as well
        as may be linked to such information from other sources.
      </p>

      <p>
        Information on legal basis: If we ask users for their consent (e.g. in
        the context of a so-called “cookie banner consent”), the legal basis for
        processing is this consent. Otherwise, user data will be processed on
        the basis of our legitimate interests (i.e. interest in the analysis,
        optimisation and economic operation of our online services. We refer you
        to the note on the use of cookies in this privacy policy.
      </p>

      <p>
        Processed data types: Usage data (e.g. websites visited, interest in
        content, access times), Meta/communication data (e.g. device
        information, IP addresses), Contact data (e.g. e-mail, telephone
        numbers), Content data (e.g. text input, photographs, videos).
        <br />
        Data subjects: Users (e.g. website visitors, users of online services),
        Communication partner (Recipients of e-mails, letters, etc.).
        <br />
        Purposes of Processing: Provision of our online services and usability,
        contact requests and communication, Direct marketing (e.g. by e-mail or
        postal), Targeting (e.g. profiling based on interests and behaviour, use
        of cookies), Interest-based and behavioral marketing, Profiling
        (Creating user profiles).
        <br />
        Legal Basis: Consent (Article 6 (1) (a) GDPR), Legitimate Interests
        (Article 6 (1) (f) GDPR).
      </p>

      <h2>Erasure of data</h2>
      <p>
        The data processed by us will be erased in accordance with the statutory
        provisions as soon as their processing is revoked or other permissions
        no longer apply (e.g. if the purpose of processing this data no longer
        applies or they are not required for the purpose).
      </p>

      <p>
        If the data is not deleted because they are required for other and
        legally permissible purposes, their processing is limited to these
        purposes. This means that the data will be restricted and not processed
        for other purposes. This applies, for example, to data that must be
        stored for commercial or tax reasons or for which storage is necessary
        to assert, exercise or defend legal claims or to protect the rights of
        another natural or legal person.
      </p>

      <p>
        Further information on the erasure of personal data can also be found in
        the individual data protection notices of this privacy policy.
      </p>

      <h2>Changes and updates to the privacy policy</h2>
      <p>
        We kindly ask you to inform yourself regularly about the contents of our
        data protection declaration. We will adjust the privacy policy as
        changes in our data processing practices make this necessary. We will
        inform you as soon as the changes require your cooperation (e.g.
        consent) or other individual notification.
      </p>

      <h2>Rights of data subjects</h2>
      <p>
        As data subject, you are entitled to various rights under the GDPR,
        which arise in particular from Articles 15 to 18 and 21 of the GDPR:
      </p>

      <p>
        Right to Object: You have the right, on grounds arising from your
        particular situation, to object at any time to the processing of your
        personal data which is based on letter (e) or (f) of Article 6(1) GDPR ,
        including profiling based on those provisions.Where personal data are
        processed for direct marketing purposes, you have the right to object at
        any time to the processing of the personal data concerning you for the
        purpose of such marketing, which includes profiling to the extent that
        it is related to such direct marketing.
        <br />
        Right of withdrawal for consents: You have the right to revoke consents
        at any time.
        <br />
        Right of access: You have the right to request confirmation as to
        whether the data in question will be processed and to be informed of
        this data and to receive further information and a copy of the data in
        accordance with the provisions of the law.
        <br />
        Right to rectification: You have the right, in accordance with the law,
        to request the completion of the data concerning you or the
        rectification of the incorrect data concerning you.
        <br />
        Right to Erasure and Right to Restriction of Processing: In accordance
        with the statutory provisions, you have the right to demand that the
        relevant data be erased immediately or, alternatively, to demand that
        the processing of the data be restricted in accordance with the
        statutory provisions. Right to data portability: You have the right to
        receive data concerning you which you have provided to us in a
        structured, common and machine-readable format in accordance with the
        legal requirements, or to request its transmission to another
        controller.
        <br />
        Complaint to the supervisory authority: You also have the right, under
        the conditions laid down by law, to lodge a complaint with a supervisory
        authority, in particular in the Member State of your habitual residence,
        place of work or place of the alleged infringement if you consider that
        the processing of personal data relating to you infringes the GDPR.
      </p>

      <h2>Terminology and definitions</h2>
      <p>
        This section provides an overview of the terms used in this privacy
        policy. Many of the terms are drawn from the law and defined mainly in
        Article 4 GDPR. The legal definitions are binding. The following
        explanations, on the other hand, are intended above all for the purpose
        of comprehension. The terms are sorted alphabetically.
      </p>

      <p>
        Controller: “Controller” means the natural or legal person, public
        authority, agency or other body which, alone or jointly with others,
        determines the purposes and means of the processing of personal data.
        <br />
        Conversion Tracking: “Conversion Tracking” refers to a procedure by
        which the effectiveness of marketing measures can be determined. As a
        rule, a cookie is stored on the devices of the users within the websites
        on which the marketing measures are carried out and then called up again
        on the target website (e.g. this enables us to track whether the ads we
        placed on other websites were successful).
        <br />
        Conversion tracking: Conversion tracking is a method used to evaluate
        the effectiveness of marketing measures. For this purpose, a cookie is
        usually stored on the devices of the users within the websites on which
        the marketing measures take place and then called up again on the target
        website (e.g. we can thus trace whether the advertisements placed by us
        on other websites were successful).
        <br />
        IP Masking: IP masking is a method by which the last octet, i.e. the
        last two numbers of an IP address, are deleted so that the IP address
        alone can no longer be used to uniquely identify a person. IP masking is
        therefore a means of pseudonymising processing methods, particularly in
        online marketing.
        <br />
        Interest-based and behavioral marketing: Interest-related and/or
        behaviour-related marketing is the term used when potential user
        interest in advertisements and other content is predicted if possible.
        This is done on the basis of information on the previous behaviour of
        users (e.g. visiting and staying on certain websites, purchasing
        behaviour or interaction with other users), which is stored in a
        so-called profile. For these purposes cookies are usually used. Personal
        Data: “personal data” means any information relating to an identified or
        identifiable natural person (“data subject”); an identifiable natural
        person is one who can be identified, directly or indirectly, in
        particular by reference to an identifier such as a name, an
        identification number, location data, an online identifier or to one or
        more factors specific to the physical, physiological, genetic, mental,
        economic, cultural or social identity of that natural person.
        Processing: The term “processing” covers a wide range and practically
        every handling of data, be it collection, evaluation, storage,
        transmission or erasure. Profiling: “Profiling” means any automated
        processing of personal data consisting in the use of such personal data
        to analyse, evaluate or predict certain personal aspects relating to a
        natural person (depending on the type of profiling, this includes
        information regarding age, gender, location and movement data,
        interaction with websites and their contents, shopping behaviour, social
        interactions with other people) (e.g. interests in certain contents or
        products, click behaviour on a website or the location). Cookies and web
        beacons are often used for profiling purposes.
        <br />
        Remarketing: Remarketing” or “retargeting” is the term used, for
        example, to indicate for advertising purposes which products a user is
        interested in on a website in order to remind the user of these products
        on other websites, e.g. in advertisements.
        <br />
        Targeting: Tracking” is the term used when the behaviour of users can be
        traced across several websites. As a rule, behavior and interest
        information with regard to the websites used is stored in cookies or on
        the servers of the tracking technology providers (so-called profiling).
        This information can then be used, for example, to display
        advertisements to users presumably corresponding to their interests.
        <br />
        Web Analytics: Web Analytics serves the evaluation of visitor traffic of
        online services and can determine their behavior or interests in certain
        information, such as content of websites. With the help of web
        analytics, website owners, for example, can recognize at what time
        visitors visit their website and what content they are interested in.
        This allows them, for example, to optimize the content of the website to
        better meet the needs of their visitors. For purposes of web analytics,
        pseudonymous cookies and web beacons are frequently used in order to
        recognise returning visitors and thus obtain more precise analyses of
        the use of an online service.
      </p>
    </div>
  );
}
