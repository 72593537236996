import React from 'react';
import './DiscoverLink.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowTopRight } from '../icons/arrow-top-right.svg';

export interface Props {
  className?: string;
}

export default function DiscoverLink(props: Props) {
  const scope = 'DiscoverLink';
  const className = [scope, props.className].filter(Boolean).join(' ');
  const { t } = useTranslation();

  return (
    <a
      className={className}
      href="https://www.diesel.com"
      rel="noreferrer"
      target="_blank"
    >
      <span>{t('discover-more-at-diesel')}</span>
      <ArrowTopRight />
    </a>
  );
}
