import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import nftImage from './nft.svg';

export interface Props {
  className?: string;
}

export default function Phase3SectionCollectionNftCollectionText(props: Props) {
  const scope = 'Phase3SectionCollectionNftCollectionText';
  const { t } = useTranslation();
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <div className={className}>
      <img alt="NFT" className={`${scope}-image`} src={nftImage} />
      <div className={`${scope}-text`}>{t('collection')}</div>
    </div>
  );
}
