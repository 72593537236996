import React from 'react';
import './SocialLinks.scss';
import { ReactComponent as IconFacebook } from '../icons/social-facebook.svg';
import { ReactComponent as IconInstagram } from '../icons/social-instagram.svg';
import { ReactComponent as IconX } from '../icons/social-x.svg';
import { ReactComponent as IconYoutube } from '../icons/social-youtube.svg';

export interface Props {
  className?: string;
}

export default function SocialLinks(props: Props) {
  const scope = 'SocialLinks';
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <div className={className}>
      <a
        className={`${scope}-item`}
        href="https://www.facebook.com/Diesel"
        rel="noreferrer"
        target="_blank"
      >
        <span className={`${scope}-labelInvisible`}>Facebook</span>
        <IconFacebook className={`${scope}-icon`} />
      </a>
      <a
        className={`${scope}-item`}
        href="https://www.instagram.com/diesel/"
        rel="noreferrer"
        target="_blank"
      >
        <span className={`${scope}-labelInvisible`}>Instagram</span>
        <IconInstagram className={`${scope}-icon`} />
      </a>
      <a
        className={`${scope}-item`}
        href="https://twitter.com/DIESEL"
        rel="noreferrer"
        target="_blank"
      >
        <span className={`${scope}-labelInvisible`}>X</span>
        <IconX className={`${scope}-icon`} />
      </a>
      <a
        className={`${scope}-item`}
        href="https://www.youtube.com/user/DieselPlanet"
        rel="noreferrer"
        target="_blank"
      >
        <span className={`${scope}-labelInvisible`}>YouTube</span>
        <IconYoutube className={`${scope}-icon`} />
      </a>
    </div>
  );
}
