import { useTranslation } from 'react-i18next';
import './FormResult.scss';

export interface Props {
  className?: string;
  confirmation?: boolean;
}

export default function Phase1FormResult(props: Props) {
  const scope = 'Phase1FormResult';
  const { t } = useTranslation();
  const className = [
    scope,
    props.confirmation && `${scope}--confirmation`,
    props.className,
  ]
    .filter(Boolean)
    .join(' ');
  const title = props.confirmation
    ? t('phase1-email-confirmation-title')
    : t('phase1-form-result-title');
  const text = props.confirmation
    ? t('phase1-email-confirmation-text')
    : t('phase1-form-result-text');

  return (
    <div className={className}>
      <h2 className={`${scope}-title`}>{title}</h2>
      <div className={`${scope}-text`}>{text}</div>
    </div>
  );
}
