import React, { useCallback, useEffect, useState } from 'react';
import './FullScreenImage.scss';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { ReactComponent as ArrowLeft } from '../icons/arrow-narrow-left.svg';
import { ReactComponent as ArrowRight } from '../icons/arrow-narrow-right.svg';

interface Props {
  onClose: () => void;
  images: string[];
  activeIndex?: number;
}

export default function FullScreenImage({
  onClose,
  images,
  activeIndex: activeIndexArg = 0,
}: Props) {
  const scope = 'FullScreenImage';

  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(activeIndexArg);

  const selectImage = (index: number) => {
    if (index < 0 || index >= images.length) {
      return;
    }
    setActiveIndex(index);
  };

  const handleSlideChange = (index: number) => {
    setActiveIndex(index);
  };

  const handleEscapeKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [handleEscapeKeyPress]);

  return (
    <div className={`${scope}-fullScreenImageContainer`}>
      <div className={`${scope}-carouselAndTextContainer`}>
        <div className={`${scope}-titleMobile`}>
          {t('phase3-experience-subtitle')}
        </div>
        <div className={`${scope}-carouselAndButtonsContainer`}>
          <button
            aria-label="Previous"
            className={`${scope}-controlButton ${scope}-controlButtonPrevDesktop`}
            disabled={activeIndex === 0}
            onClick={() => selectImage(activeIndex - 1)}
            type="button"
          >
            <ArrowLeft />
          </button>
          <Carousel
            selectedItem={activeIndex}
            useKeyboardArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            showArrows={false}
            autoPlay={true}
            autoFocus={true}
            infiniteLoop={true}
            onChange={handleSlideChange}
          >
            {images.map((watch, index) => {
              return (
                <div key={index} className={`${scope}-singleImageContainer`}>
                  <img
                    alt="Gallery Image"
                    className={`${scope}-smallImage`}
                    src={`${images[index]}`}
                  />
                </div>
              );
            })}
          </Carousel>
          <button
            aria-label="Next"
            className={`${scope}-controlButton ${scope}-controlButtonNextDesktop`}
            disabled={activeIndex === images.length - 1}
            onClick={() => selectImage(activeIndex + 1)}
            type="button"
          >
            <ArrowRight />
          </button>
        </div>
        <div className={`${scope}-textContainer`}>
          <div className={`${scope}-titleDesktop`}>
            {t('phase3-experience-subtitle')}
          </div>
          <div className={`${scope}-closeButtonContainer`}>
            <button
              aria-label="Previous"
              className={`${scope}-controlButton ${scope}-controlButtonPrevMobile`}
              disabled={activeIndex === 0}
              onClick={() => selectImage(activeIndex - 1)}
              type="button"
            >
              <ArrowLeft />
            </button>
            <div className={`${scope}-closeButton`} onClick={onClose}>
              {t('close')}
            </div>
            <button
              aria-label="Next"
              className={`${scope}-controlButton ${scope}-controlButtonNextMobile`}
              disabled={activeIndex === images.length - 1}
              onClick={() => selectImage(activeIndex + 1)}
              type="button"
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
