export interface Props {
  className?: string;
}

export default function Imprint(props: Props) {
  const scope = 'Imprint';
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <div className={className}>
      The site is fully managed and controlled by
      <br />
      Artificial Rome GmbH, <br />
      Ritterstr. 2, <br />
      10969 Berlin <br />
      <br />
      who will also act as Data Controller and can be reached out to via
      <br />
      <a href="mailto:hello@artificialrome.com">hello@artificialrome.com</a>
      <br />
      <br />
      Amtsgericht Charlottenburg (Berlin) HRB 149669 B
    </div>
  );
}
