import { Outlet } from 'react-router-dom';
import './index.scss';
import { Link } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../App';
import { ReactComponent as Logo } from '../../components/logo.svg';
import Phase1Footer from './Footer';
import Phase1Header from './Header';

export interface Props {
  className?: string;
}

export default function Phase1(props: Props) {
  const scope = 'Phase1';
  const className = [scope, props.className].filter(Boolean).join(' ');
  const homeUrl = buildRoutePath(phasePaths['1'], true);

  return (
    <div className={className}>
      <Phase1Header className={`${scope}-header`} />

      <div className={`${scope}-body`}>
        <Link to={homeUrl} className={`${scope}-logoLink`}>
          <Logo
            aria-description="Diesel for successful living"
            className={`${scope}-logo`}
          />
        </Link>
        <div className={`${scope}-content`}>
          <Outlet />
        </div>
      </div>

      <Phase1Footer className={`${scope}-footer`} />
    </div>
  );
}
