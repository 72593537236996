import React from 'react';
import './App.scss';
import {
  Navigate,
  RouteObject,
  RouterProvider,
  createHashRouter,
} from 'react-router-dom';
import Imprint from './components/Imprint';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import { useSystemDevice } from './hooks/useSystemDevice';
import Phase1 from './routes/Phase1';
import Phase1Form from './routes/Phase1/Form';
import Phase1FormResult from './routes/Phase1/FormResult';
import Phase1Legal from './routes/Phase1/Legal';
import Phase1NftWallet from './routes/Phase1/NftWallet';
import Phase2ContentPage, {
  loader as phase2ContentPageLoader,
} from './routes/Phase2/ContentPage';
import Phase2Error from './routes/Phase2/Error';
import Phase2Main, { loader as phase2MainLoader } from './routes/Phase2/Main';
import Phase3ContentPage, {
  loader as phase3ContentPageLoader,
} from './routes/Phase3/ContentPage';
import Phase3Error from './routes/Phase3/Error';
import Phase3Main, {
  loader as phase3MainLoader,
  sections,
} from './routes/Phase3/Main';
import Phase3SectionWatches from './routes/Phase3/SectionWatches';

import Root from './routes/Root';

export const phasePaths = {
  '1': 'phase-1',
  '2': 'phase-2',
  '3': 'phase-3',
};

export type Phase = keyof typeof phasePaths;

function isPhase(str: string): str is Phase {
  return str in phasePaths;
}

const activePhase = process.env.REACT_APP_ACTIVE_PHASE ?? '1';
if (isPhase(activePhase)) {
  phasePaths[activePhase] = '';
}

const routes: RouteObject[] = [
  {
    path: '',
    element: <Root />,
    children: [
      {
        path: buildRoutePath(phasePaths['1']),
        element: <Phase1 />,
        children: [
          {
            path: '',
            element: <Phase1NftWallet />,
            children: [
              {
                index: true,
                element: <Phase1Form />,
              },
              {
                path: 'email-confirmation',
                element: <Phase1FormResult confirmation={true} />,
              },
            ],
          },
          {
            element: <Phase1Legal />,
            path: 'legal',
          },
        ],
      },
      {
        path: buildRoutePath(phasePaths['2']),
        element: (
          <Navigate
            replace={true}
            to={buildRoutePath([phasePaths['2'], 'detect', '1'], true)}
          />
        ),
      },
      {
        path: buildRoutePath([phasePaths['2'], ':locale', ':page']),
        element: <Phase2Main />,
        errorElement: <Phase2Error />,
        loader: phase2MainLoader,
      },
      {
        path: buildRoutePath([phasePaths['2'], ':locale', 'terms']),
        element: (
          <Phase2ContentPage>
            <TermsAndConditions />
          </Phase2ContentPage>
        ),
        errorElement: <Phase2Error />,
        loader: phase2ContentPageLoader,
      },
      {
        path: buildRoutePath([phasePaths['2'], ':locale', 'imprint']),
        element: (
          <Phase2ContentPage>
            <Imprint />
          </Phase2ContentPage>
        ),
        errorElement: <Phase2Error />,
        loader: phase2ContentPageLoader,
      },
      {
        path: buildRoutePath([phasePaths['2'], ':locale', 'privacy']),
        element: (
          <Phase2ContentPage>
            <PrivacyPolicy />
          </Phase2ContentPage>
        ),
        errorElement: <Phase2Error />,
        loader: phase2ContentPageLoader,
      },
      {
        path: 'phase-2/booking-confirmation',
        element: (
          <Navigate
            replace={true}
            to={buildRoutePath([phasePaths['2'], 'detect', '3'], true)}
          />
        ),
      },
      {
        path: buildRoutePath([phasePaths['2'], ':locale', 'watch']),
        element: (
          <Phase2ContentPage data-name={'watches'} showFooter={false}>
            <Phase3SectionWatches
              active={true}
              sectionIndex={0}
              sectionsCount={sections.length}
              isSeparatePage={true}
            />
          </Phase2ContentPage>
        ),
        errorElement: <Phase2Error />,
        loader: phase2ContentPageLoader,
      },
      {
        path: buildRoutePath(phasePaths['3']),
        element: (
          <Navigate
            replace={true}
            to={buildRoutePath([phasePaths['3'], 'detect', '1'], true)}
          />
        ),
      },
      {
        path: buildRoutePath([phasePaths['3'], ':locale', ':page']),
        element: <Phase3Main />,
        errorElement: <Phase3Error />,
        loader: phase3MainLoader,
      },
      {
        path: buildRoutePath([phasePaths['3'], ':locale', 'terms']),
        element: (
          <Phase3ContentPage>
            <TermsAndConditions />
          </Phase3ContentPage>
        ),
        errorElement: <Phase3Error />,
        loader: phase3ContentPageLoader,
      },
      {
        path: buildRoutePath([phasePaths['3'], ':locale', 'imprint']),
        element: (
          <Phase3ContentPage>
            <Imprint />
          </Phase3ContentPage>
        ),
        errorElement: <Phase3Error />,
        loader: phase3ContentPageLoader,
      },
      {
        path: buildRoutePath([phasePaths['3'], ':locale', 'privacy']),
        element: (
          <Phase3ContentPage>
            <PrivacyPolicy />
          </Phase3ContentPage>
        ),
        errorElement: <Phase3Error />,
        loader: phase3ContentPageLoader,
      },
      {
        path: '*',
        element: <Navigate relative="path" replace={true} to=".." />,
      },
    ],
  },
];

if (activePhase === '1') {
  routes.unshift(
    {
      path: 'phase-1/email-confirmation',
      element: <Navigate replace={true} to="/email-confirmation" />,
    },
    ...routes,
  );
}

const router = createHashRouter(routes);

export default function App() {
  const scope = 'App';
  const systemDevice = useSystemDevice();
  const className = [scope, `${scope}--${systemDevice}`].join(' ');

  return (
    <div className={className}>
      <RouterProvider router={router} />
    </div>
  );
}

export function buildRoutePath(
  segments: string | number | (string | number)[],
  absolute = false,
) {
  const path = Array.isArray(segments)
    ? segments.filter(Boolean).join('/')
    : `${segments}`;
  return absolute ? `/${path}` : path;
}
