import './index.scss';
import { useTranslation } from 'react-i18next';
import { SectionProps } from '../Main';

export default function Phase3SectionThanks(props: SectionProps) {
  const scope = 'Phase3SectionThanks';
  const { t } = useTranslation();
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <section className={className}>
      <div className={`${scope}-body`}>
        <div className={`${scope}-title`}>{t('thank-you')}</div>
        <div className={`${scope}-text`}>{t('phase3-thanks-text')}</div>
      </div>
    </section>
  );
}
