import { TFunction } from 'i18next';
import { buildRoutePath } from '../../App';

export default function (t: TFunction<'translation'>) {
  return [
    {
      path: buildRoutePath(['..', '2']),
      text: t('phase2-trailer-title'),
    },
    {
      path: buildRoutePath(['..', '3']),
      text: t('phase2-registration-title'),
    },
    {
      path: buildRoutePath(['..', '4']),
      text: `NFT ${t('collection') as string}`,
    },
    {
      path: buildRoutePath(['..', '5']),
      text: t('retail-title'),
    },
  ];
}
