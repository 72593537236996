import React from 'react';
import './index.scss';
import { SYSTEM_DEVICE, useSystemDevice } from '../../../hooks/useSystemDevice';
import { SectionProps } from '../Main';
import Phase2SectionCollectionDesktop from './Desktop';
import Phase2SectionCollectionMobile from './Mobile';

export default function Phase2SectionCollection(props: SectionProps) {
  const scope = 'Phase2SectionCollection';
  const systemDevice = useSystemDevice();
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <section className={className}>
      {systemDevice === SYSTEM_DEVICE.desktop ? (
        <Phase2SectionCollectionDesktop
          active={props.active}
          className={`${scope}-body`}
          sectionIndex={props.sectionIndex}
          sectionsContainer={props.sectionsContainer}
          sectionsCount={props.sectionsCount}
        />
      ) : (
        <Phase2SectionCollectionMobile className={`${scope}-body`} />
      )}
    </section>
  );
}
