import React, { SyntheticEvent } from 'react';
import './Polygon.scss';
import { ReactComponent as PolygonInnerBlackSilver } from '../icons/polygon-inner-black-silver.svg';
import { ReactComponent as PolygonInner } from '../icons/polygon-inner.svg';
import { ReactComponent as PolygonOuter } from '../icons/polygon.svg';

interface Args {
  isActive: boolean;
  color: string;
  onClick?: (event: SyntheticEvent) => unknown;
  shouldShowBlackSilver?: boolean;
  className?: string;
}

const Polygon = ({
  isActive,
  color,
  onClick,
  shouldShowBlackSilver = false,
  className = '',
}: Args) => {
  const scope = 'Polygon';

  return (
    <div
      className={`${scope}-wrapper ${className}`}
      data-color={color}
      onClick={onClick}
    >
      <PolygonOuter
        className={`${scope}-outer ${isActive ? `isActive` : ''}`}
        data-is-active={isActive}
      />
      {shouldShowBlackSilver ? (
        <PolygonInnerBlackSilver className={`${scope}-inner`} />
      ) : (
        <PolygonInner className={`${scope}-inner`} data-color={color} />
      )}
    </div>
  );
};

export default Polygon;
