import React, {
  ChangeEvent,
  FormEvent,
  TransitionEvent,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './Form.scss';
import { Link } from 'react-router-dom';
import { createRegistration } from '../../api';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import Phase1FormResult from './FormResult';

export interface Props {
  className?: string;
}

export default function Phase1Form(props: Props) {
  const scope = 'Phase1Form';
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isFading, setIsFading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const inputElementRef = useRef<HTMLInputElement>(null);
  const className = [
    scope,
    isFading && `${scope}--fading`,
    isSubmitted && `${scope}--submitted`,
    props.className,
  ]
    .filter(Boolean)
    .join(' ');

  function onEmailChange(event: ChangeEvent<HTMLInputElement>) {
    inputElementRef.current?.setCustomValidity('');
    setEmail(event.target.value);
  }

  function onTermsChange(event: ChangeEvent<HTMLInputElement>) {
    setIsTermsAccepted(event.target.checked);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!email) {
      return;
    }
    setIsSubmitting(true);
    createRegistration(email)
      .then(() => {
        setIsFading(true);
      })
      .catch((error: Error) => {
        inputElementRef.current?.setCustomValidity(error.message);
        inputElementRef.current?.reportValidity();
        inputElementRef.current?.form?.reportValidity();
        inputElementRef.current?.form?.requestSubmit();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function onTransitionEnd(event: TransitionEvent<HTMLFormElement>) {
    if (
      event.target === event.currentTarget &&
      event.propertyName === 'opacity' &&
      !isSubmitted &&
      isFading
    ) {
      setIsSubmitted(true);
      setIsFading(false);
    }
  }

  return (
    <form
      className={className}
      onSubmit={onSubmit}
      onTransitionEnd={onTransitionEnd}
    >
      {isSubmitted ? (
        <Phase1FormResult />
      ) : (
        <>
          <div className={`${scope}-content`}>
            <div className={`${scope}-title`}>{t('phase1-form-title')}</div>
            <div className={`${scope}-text`}>
              <Trans i18nKey={'phase1-form-text'} />
            </div>
          </div>
          <fieldset className={`${scope}-fieldset`} disabled={isSubmitting}>
            <div className={`${scope}-fieldsetFlex`}>
              <Input
                className={`${scope}-inputEmail`}
                label={t('form-label-email')}
                onChange={onEmailChange}
                pattern="^\S+@\S+\.\S+$"
                placeholder="mario.rossi@gmail.com"
                ref={inputElementRef}
                required={true}
                type="email"
                value={email}
              />

              <Checkbox
                checked={isTermsAccepted}
                className={`${scope}-inputTerms`}
                onChange={onTermsChange}
                required={true}
              >
                <Trans i18nKey="phase1-registration-terms-label">
                  <Link to="../legal" />
                </Trans>
              </Checkbox>

              <div className={`${scope}-text`}>
                <Trans i18nKey={'step-inside-house-of-diesel'}>
                  <a
                    href="https://www.diesel.com/shop/category/diesel-stories-magazine-houseofdiesel?evt="
                    rel="noreferrer"
                    target="_blank"
                  />
                </Trans>
              </div>

              <Button
                className={`${scope}-buttonSubmit`}
                text={t('register-now')}
                type="submit"
              />
            </div>
          </fieldset>
        </>
      )}
    </form>
  );
}
