import { useCookies } from 'react-cookie';

const scripts = [
  {
    content: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '329288722992757');
      fbq('track', 'PageView');
    `,
  },
  {
    src: 'https://www.googletagmanager.com/gtag/js?id=DC-8199263',
  },
  {
    content: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'DC-8199263');
    `,
  },
  {
    content: `
      gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'u1': '[country]',
        'u2': '[pageURL]',
        'send_to': 'DC-8199263/count0/diese00c+unique'
      });
    `,
  },
  {
    content: `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
    )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
        ttq.load('CIR8OKJC77UBI1EI27B0');
        ttq.page();
      }(window, document, 'ttq');
    `,
  },
];

export default function TrackingCodes() {
  const [cookies] = useCookies(['accept-cookies']);

  if (cookies['accept-cookies'] !== 'all') {
    return null;
  }

  if (document.getElementById('tracking-codes')) {
    return null;
  }

  const element = document.createElement('div');
  element.id = 'tracking-codes';
  document.body.appendChild(element);

  scripts.forEach((data) => {
    const script = document.createElement('script');
    if (data.src) {
      script.async = true;
      script.src = data.src;
    } else if (data.content) {
      script.innerHTML = data.content;
    }
    element.appendChild(script);
  });

  return null;
}
