import React, {
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import './Input.scss';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  type?: Exclude<HTMLInputTypeAttribute, 'checkbox'>;
}

const Input = forwardRef<HTMLInputElement, Props>(function Input(props, ref) {
  const scope = 'Input';

  const { children, className: propsClassName, label, ...inputProps } = props;

  const className = [scope, propsClassName].filter(Boolean).join(' ');

  return (
    <label className={className}>
      {(children || label) && (
        <div className={`${scope}-label`}>{children || label}</div>
      )}
      <input className={`${scope}-input`} ref={ref} {...inputProps} />
    </label>
  );
});

export default Input;
