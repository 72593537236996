import React, {
  ChangeEvent,
  FormEvent,
  TransitionEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './Form.scss';
import { Link } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../../App';
import {
  BookSeatErrorResponseData,
  TimeSlotData,
  bookSeat,
  fetchTimeSlots,
} from '../../../api';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import DateTimeSelect from '../../../components/DateTimeSelect';
import Input from '../../../components/Input';

export interface Props {
  className?: string;
}

export default function Phase2SectionRegistrationForm(props: Props) {
  const scope = 'Phase2SectionRegistrationForm';
  const { i18n, t } = useTranslation();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [date, setDate] = useState<Date>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isFading, setIsFading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [timeSlots, setTimeSlots] = useState<TimeSlotData[]>([]);
  const selectElementRef = useRef<HTMLSelectElement>(null);
  const className = [scope, isFading && `${scope}--fading`, props.className]
    .filter(Boolean)
    .join(' ');

  useEffect(() => {
    fetchTimeSlots().then(
      (result) => setTimeSlots(result),
      () => setTimeSlots([]),
    );
  }, []);

  const dateTimeOptions = timeSlots
    .map((timeSlot) => {
      const dateString = `${timeSlot.start_time.split(' ').join('T')}.000Z`;
      return new Date(dateString);
    })
    .filter((date) => {
      return date.valueOf() > new Date().valueOf();
    })
    .sort((date1, date2) => {
      return date1.valueOf() - date2.valueOf();
    });

  function onChangeName(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function onChangeEmail(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function onChangeDate(
    event: ChangeEvent<HTMLSelectElement>,
    value: Date | null,
  ) {
    selectElementRef.current?.setCustomValidity('');
    setDate(value ?? undefined);
  }

  function onChangeTerms(event: ChangeEvent<HTMLInputElement>) {
    setIsTermsAccepted(event.target.checked);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!email || !date) {
      return;
    }
    setIsSubmitting(true);
    const [dateString, timeString] = date.toISOString().split('T');
    const formattedDateString = `${dateString} ${timeString.split('.')[0]}`;
    bookSeat({
      email,
      name,
      is_japanese: i18n.language === 'ja',
      start_time: formattedDateString,
      time_offset: date.getTimezoneOffset(),
    })
      .then(() => {
        setErrorMessage('');
        setIsFading(true);
      })
      .catch((error: Error | BookSeatErrorResponseData) => {
        void fetchTimeSlots();
        if (!(error instanceof Error) && error.code === 'taken_seat') {
          selectElementRef.current?.setCustomValidity(
            t('phase2-form-error-taken-seat'),
          );
          selectElementRef.current?.reportValidity();
          selectElementRef.current?.form?.reportValidity();
          selectElementRef.current?.form?.requestSubmit();
          setErrorMessage('');
        } else {
          setErrorMessage(t('phase2-form-error-bad-request'));
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function onTransitionEnd(event: TransitionEvent<HTMLFormElement>) {
    if (
      event.target === event.currentTarget &&
      event.propertyName === 'opacity' &&
      !isSubmitted &&
      isFading
    ) {
      setIsSubmitted(true);
      setIsFading(false);
    }
  }

  return (
    <form
      className={className}
      onSubmit={onSubmit}
      onTransitionEnd={onTransitionEnd}
    >
      {isSubmitted ? (
        <div className={`${scope}-result`}>
          <div className={`${scope}-resultTitle`}>
            {t('phase2-form-result-title')}
          </div>
          <div className={`${scope}-resultText`}>
            {t('phase2-form-result-text')}
          </div>
          <div className={`${scope}-resultText`}>
            <Trans i18nKey={'step-inside-house-of-diesel'}>
              <a
                href="https://www.diesel.com/shop/category/diesel-stories-magazine-houseofdiesel?evt=a0S1v00000R1Oz8EAF"
                rel="noreferrer"
                target="_blank"
              />
            </Trans>
          </div>
        </div>
      ) : (
        <>
          <div className={`${scope}-content`}>
            <div className={`${scope}-title`}>{t('phase2-form-title')}</div>
            <div className={`${scope}-text`}>
              <Trans i18nKey={'phase2-registration-text'} />
            </div>
          </div>
          <fieldset className={`${scope}-fieldset`} disabled={isSubmitting}>
            <div className={`${scope}-fieldsetFlex`}>
              <Input
                className={`${scope}-inputName`}
                label={t('form-label-name')}
                onChange={onChangeName}
                placeholder="Mario Rossi"
                required={true}
                type="text"
                value={name}
              />

              <Input
                className={`${scope}-inputEmail`}
                label={t('form-label-email')}
                onChange={onChangeEmail}
                pattern="^\S+@\S+\.\S+$"
                placeholder="mario.rossi@gmail.com"
                required={true}
                type="email"
                value={email}
              />

              <DateTimeSelect
                className={`${scope}-inputTimeSlot`}
                label={t('form-label-time-slot')}
                onChange={onChangeDate}
                options={dateTimeOptions}
                ref={selectElementRef}
                required={true}
                value={date}
              />

              <Checkbox
                checked={isTermsAccepted}
                className={`${scope}-inputTerms`}
                onChange={onChangeTerms}
                required={true}
              >
                <Trans i18nKey="phase2-registration-terms-label">
                  <a
                    href="https://it.diesel.com/en/help-show?content=terms"
                    rel="noreferrer"
                    target="_blank"
                  />
                  <Link
                    to={buildRoutePath(
                      [phasePaths['2'], i18n.language, 'terms'],
                      true,
                    )}
                  />
                </Trans>
              </Checkbox>

              {errorMessage && (
                <p className={`${scope}-errorMessage`}>{errorMessage}</p>
              )}

              <Button
                className={`${scope}-buttonSubmit`}
                text={t('register-now')}
                type="submit"
              />
            </div>
          </fieldset>
        </>
      )}
    </form>
  );
}
