import React from 'react';
import './index.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { buildRoutePath, phasePaths } from '../../../App';
import LanguageSwitcher from '../../../components/LanguageSwitcher';
import LegalLinks from '../../../components/LegalLinks';
import MadeBySoil from '../../../components/MadeBySoil';
import SocialLinks from '../../../components/SocialLinks';
import { SYSTEM_DEVICE, useSystemDevice } from '../../../hooks/useSystemDevice';
import { SectionProps } from '../Main';
import Phase3SectionExperienceDesktop from './Desktop';
import Phase3SectionExperienceMobile from './Mobile';

export default function Phase3SectionExperience(props: SectionProps) {
  const scope = 'Phase3SectionExperience';
  const { i18n, t } = useTranslation();
  const systemDevice = useSystemDevice();
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <section className={className}>
      <div className={`${scope}-body`}>
        <div className={`${scope}-content`}>
          <div className={`${scope}-title`}>
            <Trans i18nKey="phase3-experience-title" />
          </div>
          <div className={`${scope}-text`}>{t('phase3-experience-text')}</div>
        </div>
        {systemDevice === SYSTEM_DEVICE.desktop ? (
          <Phase3SectionExperienceDesktop className={`${scope}-gallery`} />
        ) : (
          <Phase3SectionExperienceMobile className={`${scope}-gallery`} />
        )}
      </div>
      <div className={`${scope}-footerContainer`}>
        <div className={`${scope}-footerDisclaimer`}>
          <Trans i18nKey="phase2-disclaimer-text">
            <Link
              to={buildRoutePath(
                [phasePaths['3'], i18n.language, 'terms'],
                true,
              )}
            />
          </Trans>
        </div>
        <MadeBySoil className={`${scope}-footerMadeBy`} />
        <div className={`${scope}-footerLinksAndLanguage`}>
          <SocialLinks className={`${scope}-socialLinks`} />
          <div className={`${scope}-footerLegalAndLanguage`}>
            <LegalLinks phase="3" />
            <LanguageSwitcher
              className={`${scope}-languageSwitcher`}
              phase="2"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
