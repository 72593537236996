import TermsAndConditions from '../../components/TermsAndConditions';
import './Legal.scss';

export interface Props {
  className?: string;
}

export default function Phase1Legal(props: Props) {
  const scope = 'Phase1Legal';
  const className = [scope, props.className].filter(Boolean).join(' ');

  return (
    <div className={className}>
      <TermsAndConditions className={`${scope}-content`} />
    </div>
  );
}
