interface Props {
  activeIndex: number;
  onClick: (index: number) => void;
}

export default function ColorSelectionArc({ activeIndex, onClick }: Props) {
  const generalStrokeWidth = 1;
  const activeStrokeWidth = 2;

  const getStrokeWidth = (index: number) => {
    return index === activeIndex ? activeStrokeWidth : generalStrokeWidth;
  };

  return (
    <div>
      <svg
        width="59"
        height="263"
        viewBox="0 0 59 263"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M54.4528 1.07291L53.7178 0.337891C50.4363 6.20305 47.3689 12.2042 44.5261 18.3308L45.451 18.7139C48.2383 12.7093 51.2423 6.82558 54.4528 1.07291Z"
          fill="white"
        />
        <path
          d="M54.8397 261.623L54.1053 262.358C50.8558 256.59 47.8137 250.69 44.9892 244.667L45.9142 244.284C48.683 250.185 51.6613 255.968 54.8397 261.623Z"
          fill="white"
        />
        <path
          d="M31.3312 52.8653C28.5339 62.0573 26.2172 71.4588 24.4106 81.0403L25.3547 81.4314C27.1541 71.8471 29.4656 62.4435 32.2597 53.2499L31.3312 52.8653Z"
          fill="white"
        />
        <path
          d="M20.6042 119.464L21.0138 119.294C20.8463 123.176 20.7617 127.079 20.7617 131.003C20.7617 135.205 20.8588 139.386 21.0509 143.541L20.0689 143.948C19.8649 139.658 19.7617 135.343 19.7617 131.003C19.7617 127.055 19.8471 123.126 20.0161 119.22L20.6042 119.464Z"
          fill="white"
        />
        <path
          d="M25.5633 181.672L24.6199 182.063C26.4654 191.63 28.8196 201.016 31.6534 210.191L32.5816 209.806C29.7509 200.63 27.4016 191.242 25.5633 181.672Z"
          fill="white"
        />
        {/* White */}
        <path
          d="M23.8453 21.7034L37.6047 16.0041L51.3642 21.7034L57.0635 35.4629L51.3642 49.2223L37.6047 54.9217L23.8453 49.2223L18.1459 35.4629L23.8453 21.7034Z"
          fill="black"
          fillOpacity="0.1"
          stroke="white"
          strokeWidth={getStrokeWidth(0)}
          onClick={() => onClick(0)}
        />
        <path
          d="M37.9214 27.4629L43.5782 29.806L45.9214 35.4629L43.5782 41.1197L37.9214 43.4629L32.2645 41.1197L29.9214 35.4629L32.2645 29.806L37.9214 27.4629Z"
          fill="white"
          onClick={() => onClick(0)}
        />
        {/* Charcoal */}
        <path
          d="M6.84528 85.7034L20.6047 80.0041L34.3642 85.7034L40.0635 99.4629L34.3642 113.222L20.6047 118.922L6.84528 113.222L1.14593 99.4629L6.84528 85.7034Z"
          fill="black"
          fillOpacity="0.1"
          stroke="white"
          strokeWidth={getStrokeWidth(1)}
          onClick={() => onClick(1)}
        />
        <path
          d="M20.9214 91.4629L26.5782 93.806L28.9214 99.4629L26.5782 105.12L20.9214 107.463L15.2645 105.12L12.9214 99.4629L15.2645 93.806L20.9214 91.4629Z"
          fill="#121212"
          onClick={() => onClick(1)}
        />
        {/* Gold */}
        <path
          d="M6.84528 149.703L20.6047 144.004L34.3642 149.703L40.0635 163.463L34.3642 177.222L20.6047 182.922L6.84528 177.222L1.14593 163.463L6.84528 149.703Z"
          fill="black"
          fillOpacity="0.1"
          stroke="white"
          strokeWidth={getStrokeWidth(2)}
          onClick={() => onClick(2)}
        />
        <path
          d="M20.9214 155.463L26.5782 157.806L28.9214 163.463L26.5782 169.12L20.9214 171.463L15.2645 169.12L12.9214 163.463L15.2645 157.806L20.9214 155.463Z"
          fill="#EFD782"
          onClick={() => onClick(2)}
        />
        {/* Black-silver */}
        <path
          d="M51.9975 213.703L38.238 208.004L24.4786 213.703L18.7792 227.463L24.4786 241.222L38.238 246.922L51.9975 241.222L57.6968 227.463L51.9975 213.703Z"
          fill="black"
          fillOpacity="0.1"
          stroke="white"
          strokeWidth={getStrokeWidth(3)}
          onClick={() => onClick(3)}
        />
        <path
          d="M37.9214 219.463L32.2645 221.806L29.9214 227.463L32.2645 233.12L37.9214 235.463L43.5782 233.12L45.9214 227.463L43.5782 221.806L37.9214 219.463Z"
          fill="white"
          onClick={() => onClick(3)}
        />
        <path
          d="M29.9213 227.463L32.2644 221.807L43.5781 233.12L37.9213 235.463L32.2644 233.12L29.9213 227.463Z"
          fill="#121212"
        />
      </svg>
    </div>
  );
}
