import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './CookiesConsentBanner.scss';
import { buildRoutePath, phasePaths } from '../App';
import { GlobalContext } from '../routes/Root';

export interface Props {
  className?: string;
}

export default function CookiesConsentBanner(props: Props) {
  const scope = 'CookiesConsentBanner';
  const { i18n, t } = useTranslation();
  const [cookies, setCookie] = useCookies(['accept-cookies']);
  const globalContext = useContext(GlobalContext);

  const language = i18n.language ?? ':detect';
  const className = [scope, props.className].filter(Boolean).join(' ');
  const shouldCleanup = cookies['accept-cookies'] === 'all';

  function acceptAll() {
    setCookie('accept-cookies', 'all');
    globalContext.setIsConsentVisible(false);
  }

  function acceptRequired() {
    setCookie('accept-cookies', 'required');
    globalContext.setIsConsentVisible(false);
    if (shouldCleanup) {
      location.reload();
    }
  }

  if (!globalContext.isConsentVisible && cookies['accept-cookies']) {
    return null;
  }

  return (
    <div className={className}>
      <div className={`${scope}-body`}>
        <div className={`${scope}-textBlock`}>
          <Trans i18nKey="cookie-consent-text">
            <Link
              to={buildRoutePath([phasePaths['2'], language, 'privacy'], true)}
            />
          </Trans>
        </div>
        <button
          className={`${scope}-buttonRequired`}
          onClick={acceptRequired}
          type="button"
        >
          {t('only-required')}
        </button>
        <button
          className={`${scope}-buttonAll`}
          onClick={acceptAll}
          type="button"
        >
          {t('accept-all')}
        </button>
      </div>
    </div>
  );
}
