import { useEffect, useState } from 'react';
import { ObjectValues } from '../types';

export const SYSTEM_DEVICE = {
  desktop: 'desktop',
  mobile: 'mobile',
} as const;

export type SystemDevice = ObjectValues<typeof SYSTEM_DEVICE>;

export function useSystemDevice() {
  const [systemDevice, setSystemDevice] = useState(detectDevice());
  const setCurrentSystemDevice = () => {
    setSystemDevice(detectDevice());
  };

  useEffect(() => {
    window.addEventListener('resize', setCurrentSystemDevice);
    setCurrentSystemDevice();
    return () => {
      window.removeEventListener('resize', setCurrentSystemDevice);
    };
  });

  return systemDevice;
}

const detectDevice = (): SystemDevice => {
  const userAgent = navigator.userAgent;
  const hasTouchPoints =
    navigator.maxTouchPoints > 0 || 'msMaxTouchPoints' in navigator;
  const isTouchable =
    'ontouchstart' in window ||
    hasTouchPoints ||
    matchMedia('(any-pointer: coarse)').matches;
  const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(userAgent);
  const isAndroid = userAgent.includes('Android');
  const isWindows = userAgent.includes('Windows');
  const isSurface = isWindows && isTouchable;
  const isMobileDevice = isAndroid || isIOS || isSurface;
  const isPortrait = window.innerHeight > window.innerWidth;
  const isMobileDeviceInPortraitMode = isMobileDevice && isPortrait;
  const mobileMaxWidth = '760px';
  const isNarrowScreen = window.matchMedia(
    `(max-width: ${mobileMaxWidth})`,
  ).matches;
  return isMobileDeviceInPortraitMode || isNarrowScreen
    ? SYSTEM_DEVICE.mobile
    : SYSTEM_DEVICE.desktop;
};
