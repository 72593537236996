import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './locales/en/translation.json';

export const defaultNS = 'translation';
export const fallbackLng = 'en';
export const fallbackNS = 'fallback';

const resources = {
  en: {
    [defaultNS]: translationEn,
  },
};

export const locales = Object.keys(resources);

void i18n.use(initReactI18next).init({
  debug: process.env.REACT_APP_DEBUG_I18N === 'true',
  defaultNS,
  fallbackLng,
  fallbackNS,
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;

export function getDefaultLocale() {
  const locale = locales.find((locale) => {
    const regex = new RegExp(`^${locale}\\b`);
    return regex.test(navigator.language);
  });
  return locale ?? fallbackLng;
}

export function isValidLocale(locale: string) {
  return locales.includes(locale);
}
